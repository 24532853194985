// Customizable Area Start
import React from "react";
import EmailAccountLoginController, { AuthenticationMethod, Props } from "./EmailAccountLoginController";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OtpInput from 'react-otp-input';
import { styled } from '@mui/material/styles';
import Timer from './components/Timer';


export default class OTPVerification extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        this.getOtpInputStyle = this.getOtpInputStyle.bind(this)
        this.getEmailOtpInputStyle = this.getEmailOtpInputStyle.bind(this)
    }

    getOtpInputStyle = (props: { style: any; }) => {
        return {
            ...props.style,
            borderColor: this.state.otpError ? 'red' : '#CBD5E1',
            display: 'flex',
            width: '56px',
            height: '56px',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #CBD5E1',
            borderRadius: '8px',
            fontSize: '16px',
            fontWeight: 400,
            fontFamily: "Inter",
            padding: '0',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            margin: '8px',
        };
    }

    getEmailOtpInputStyle = (props: {style: any}) => {
        return {
            ...props.style,
            borderColor: this.state.otpEmailError ? 'red' : '#CBD5E1',
            display: 'flex',
            width: '56px',
            height: '56px',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #CBD5E1',
            borderRadius: '8px',
            fontSize: '16px',
            fontWeight: 400,
            fontFamily:"Inter",
            padding: '0',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            margin: '8px',
        }
    }
    
    render() {
        const { AuthMethod } = this.state;
        return (
        <>
        {this.state.verification && (
            <Grid container style={styles.FontFam}>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div style={styles.Hero} data-test-id="backBtn" onClick={this.navTo}>
                                <ArrowBackIosIcon style={styles.backarrow} />
                                <div style={styles.BacK}>Back</div>
                            </div>
                            <Grid container sx={styles.otpContainer}>
                                <Grid item xs={12} style={styles.grid}>
                                    <div style={styles.headImg}>
                                        <img src={this.state.head} />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={styles.phone}>
                                        {AuthMethod === AuthenticationMethod.PHONE ? 'Phone number verification' : 'Email verification'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={styles.verification}>
                                        {AuthMethod === AuthenticationMethod.PHONE
                                            ? 'Please enter the verification code sent to your mobile number.'
                                            : 'Please enter the verification code sent to your email address.'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={styles.valid}>
                                        (Code is valid for 10 mins)
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={styles.phoneNumber}>
                                        {AuthMethod === AuthenticationMethod.PHONE ? this.state.phoneNumber : this.state.email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={styles.otpBox}>
                                        <OtpInput
                                            value={AuthMethod === AuthenticationMethod.PHONE? this.state.otp : this.state.emailOtp}
                                            inputType="tel"
                                            onChange={AuthMethod === AuthenticationMethod.PHONE ? this.handlePhoneOtp : this.handleEmailAddOtp}
                                            containerStyle={{ outline: 'none' }}
                                            data-test-id={AuthMethod === AuthenticationMethod.PHONE ? 'otpInput' : 'emailOtpInput'}
                                            numInputs={4}
                                            renderInput={(props) => <input {...props} placeholder="0" style={AuthMethod === AuthenticationMethod.PHONE ? this.getOtpInputStyle(props) : this.getEmailOtpInputStyle(props)} />}
                                        />
                                    </Box>
                                    {AuthMethod === AuthenticationMethod.PHONE && this.state.otpError && (
                                        <Box sx={styles.otpError}>{this.state.otpPhoneErrorMessage}</Box>
                                    )}
                                    {AuthMethod === AuthenticationMethod.EMAIL && this.state.otpEmailError && (
                                        <Box sx={styles.otpError}>{this.state.otpEmailErrorMessage}</Box>
                                    )}
                                    {this.state.resendCodeSent && (
                                        <Box sx={{ ...styles.otpError, color: '#047857' }}>
                                            The code was sent successfully
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Timer
                                        onResenCode={this.handleResendOtp}
                                        styles={{
                                            resend: styles.resend,
                                            resendBtn: styles.resendBtn,
                                            time: styles.time,
                                            resendRedBtn: styles.resendRedBtn,
                                            btn: styles.btn,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} style={styles.grid2}>
                                    <Button
                                        variant="contained"
                                        sx={styles.Btn}
                                        onClick={AuthMethod === AuthenticationMethod.PHONE ? this.handlePhoneSubmit : this.handleOtpEmail}
                                        data-test-id={AuthMethod === AuthenticationMethod.PHONE ? 'verify' : 'emailVerify'}
                                    >
                                        Continue
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={styles.imgBox}>
                        <CustomImg src={this.state.rightPic} />
                    </Box>
                </Grid>
            </Grid>
        )} 
    </>
        )
    }
}

const CustomImg = styled('img')(({ theme }) => ({
    width: '100%',
    marginTop: '42px',
    marginBottom: '40px',
    height: '100%',
    objectFit:'cover',
    [theme.breakpoints.up('md')]: {
        borderRadius: '64px 0px 0px 64px',
        height: '73%',
    },
}));
const styles = {
    Hero : {
        alignItems: 'center',
        display: 'flex',
        width: '71px',
        height: "26px",
        marginTop: '58px',
        marginLeft: '34px'
    },
    backarrow : {
        color: '#0F172A'
    },
    BacK : {
        color: '#0F172A',
        fontSize: '18px',
        fontFamily:"Inter"
    },
    otpContainer : {
        alignItems: 'center', margin: '120px 0 0 0',
        padding: { xs: '0 50px 0 50px', md: '0 70px 0 70px', lg: '0 105px 0 105px' }, display: 'flex',
        width: '100%'
    },
    grid : {
        justifyContent: 'center',
        display: 'flex'
    },
    headImg : {
        width: '100px',
        height: '100px',
        display: 'flex',
        justifyContent: 'center'
    },
    phone : {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
        margin: '17px 0px 10px 0px',
        textAlign: 'center',
        fontWeight: '400',
        fontFamily:"Inter",
        fontSize: '24px'
    },
    verification : {
        display: 'flex',
        textAlign: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
        fontSize: '14px',
        width: '100%',
        margin: '3px 0px',
        fontWeight: '400',
        fontFamily:"Inter"
    },
    valid : {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        color: '#64748B',
        fontSize: '14px',
        fontWeight: '400',
        fontFamily:"Inter",
        margin: '3px 0px'
    },
    signingIn:{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        color: '#64748B',
        fontSize: '20px',
        fontWeight: '400',
        fontFamily:"Inter",
        margin: '15px 0px'
    },
    phoneNumber : {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        fontWeight: '400',
        fontFamily:"Inter",
        fontSize: '20px',
        margin: '30px 0px'
    },
    otpBox : {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        marginBottom: '30px',
        fontFamily:"Inter"
    },
    otpInput : {
      
    },
    otpError : {
        fontWeight: '400',
        fontFamily:"Inter",
        color: '#DC2626',
        width:'100%',
        fontSize: '14px',
        display:'flex',
        justifyContent:'center'
    },
    resend : {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        fontSize: '14px',
        width: '100%',
        margin: '30px 0px',
        fontWeight: '600',
        fontFamily:"Inter"
    },
    resendRedBtn : {
        color: '#D97706',
    },
    btn: {
        marginRight: '4px',
        fontFamily:"Inter",
        fontSize: "14px",
        fontWeight: 600,
        padding: 0,
        textTransform: "none"
    },
    resendBtn : {
        marginRight: '4px',
        color: '#A8A29E',
        fontFamily:"Inter"
    },
    time : {
        color: '#0F172A',
        fontFamily:"Inter"
    },
    Btn : {
        height: '56px',
        width: '100%',
        background: 'linear-gradient(to right, #FCD34D, #D97706 )',
        fontSize: '15px',
        fontFamily:"Inter"
    },
    grid2 : {
        width: '100%'
    },
    imgBox : {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        height: { xs: '100%', md: '115%', lg: '120%' },
        width: "100%"
    },
    success: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        color: "#059669",
        fontSize: "30px",
        fontWeight: "400",
        fontFamily:"Inter"
    },
    successBox: {
        marginTop:'20px'
    },
    successContainer : {
        display: "flex",
        alignItems: 'center',
        margin: { xs: '100px 0 100px 0',md: '200px 0 0 0', lg: '300px 0 0 0' },
        justifyContent: 'center',
        padding: { xs: '0 50px 0 50px', md: '0 70px 0 70px', lg: '0 105px 0 105px' },
        width: '100%',
    },
    successheadImg : {
        width: '120px',
        height: '120px',
        display: 'flex',
        justifyContent: 'center'
    },
    successHero : {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '71px',
        height: "26px",
        marginTop: '58px',
        marginLeft: '34px',
        
    },
    FontFam:{
        fontFamily:"Inter"
    }
};

// Customizable Area End