//@ts-nocheck
import React, { Fragment } from "react";
import { createTheme, ThemeProvider, Theme, StyledEngineProvider, adaptV4Theme } from "@mui/material/styles";
import CancelIcon from '@mui/icons-material/Cancel';
// Customizable Area Start
import {
  Container,
  Input,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Dialog,
  Divider,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box, Typography, Button,
  Pagination,
  Popover,
  IconButton,
  TextField
} from "@mui/material";
import { plus, vertDots,logo ,done} from "./assets";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Layout from "../../landingpage/src/Layout.web";
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
// Customizable Area Start


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}


interface ProductItem {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    status: boolean;
    price: number;
    user_type: string;
    quantity: string;
    created_at: string;
    updated_at: string;
    images: [
      {
        id: number;
        url: string;
        type: string;
        filename: string;
      }
    ];
  };
}
const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
      secondary: {
        main: "#376fd0",
        contrastText: "#fff",
      },
      error: {
        main: "#f44336",
        contrastText: "#fff",
      }
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
// Customizable Area End


export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <Layout id="ContentManagement" navigation={this.props.navigation}>
        <>
        <div sx={{ fontFamily: 'Inter', height: "100%", width: '100%' }}>
        <style>
          {`
            .Mui-selected {
              font-family: 'Inter'; 
              font-size: 12px;
              font-weight: 700;
              line-height: 18px;
              color: #D97706;
              background-color: transparent !important;
              text-decoration: none !important;
            }

            .MuiPaginationItem-root {
              font-family: 'Inter'; 
              font-size: 12px;
              font-weight: 700;
              line-height: 18px;
              text-decoration: underline;
              background-color: transparent;
            }
          `}
        </style>

          <Box margin="30px">
            <Typography style={webStyles.pageHeading}>Health Wallet</Typography>
            <Box style={{ marginLeft: "30px" }}>
              <Typography style={webStyles.gridHeading}>Explore your medical documents</Typography>
              <Box style={{ paddingLeft: "30px" }}>
                <Grid container style={webStyles.container}>
                  {this.state.cardData.map((item : WalletResponse, index:number) => (
                    <Grid item lg={2} md={3} sm={5} xs={12} key={index}>
                      <Box style={webStyles.card}>
                        <img src={item.document_url} style={webStyles.cardImg} />
                        <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%" maxHeight="120px">
                          <Box style={webStyles.labelContainer}>
                            <Typography style={webStyles.cardHeading}>{item.wallet.document_name}</Typography>
                            <Typography style={webStyles.cardSubHeading}>File Size: 12Mb</Typography>
                          </Box>
                          <Box style={webStyles.vertDotsContainer}>
                            <Button style={webStyles.vertDotBtn} data-test-id="moreOption" color="primary" onClick={(event) => this.handleClick(event, item)}>
                              <img src={vertDots} />
                            </Button>
                            <Popover
                              PaperProps={{
                                sx: {
                                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                  borderRadius: "8px, 0px, 8px, 8px"
                                },
                              }}
                              id="simple-menu"
                              anchorEl={this.state.anchorEl}
                              keepMounted
                              open={Boolean(this.state.anchorEl)}
                              onClose={this.handleClose}
                              style={webStyles.menu}
                              data-test-id="popover"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            >
                              <MenuItem style={webStyles.menuItem} data-test-id="handleView" onClick={() => this.handleView(this.state.selectedItem.wallet.id)}>View</MenuItem>
                              <MenuItem style={webStyles.menuItem} data-test-id="handleDownload"><a style={{ textDecoration: "none", color: "#475569" }} href={this.state.selectedItem.document_url} download>Download</a></MenuItem>
                              <MenuItem style={webStyles.menuItem} data-test-id="handleRename" onClick={() => this.handleRename(this.state.selectedItem.wallet.id)}>Rename</MenuItem>
                              <MenuItem style={{ ...webStyles.menuItem, color: "#DC2626" }} data-test-id="handleDelete" onClick={this.handleDelete}>Delete</MenuItem>
                            </Popover>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <Box sx={webStyles.newDocCard}>
                  <Box style={webStyles.newDoclabelContainer}>
                    <Typography style={webStyles.addDocHeading}>
                      Add new document
                      <span style={webStyles.iconWrapper}>
                        <img src={plus} alt="Add" style={webStyles.icon} />
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Box> 
            </Box>            
            <Box style={webStyles.paginationBox}>
              <Pagination
                data-test-id="pagination"
                count={Math.ceil(this.state.totalItems / this.state.itemsPerPage)}
                page={this.state.currentPage}
                onChange={this.handlePageChange}
                boundaryCount={1}
              />
            </Box>
          </Box>
        </div>

        <Dialog onClose={this.handleViewDialogClose} maxWidth={"xl"} aria-labelledby="customized-dialog-title" open={this.state.viewDocument}>
          <Box>
            <IconButton aria-label="close" data-test-id="closeViewDialog" style={{ float: "right" }} onClick={this.handleViewDialogClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent >
            <img src={this.state.singleDocument} style={webStyles.dialogImage} />
          </DialogContent>
        </Dialog>

        <Dialog onClose={this.handleRenameDialogClose} maxWidth={"md"} aria-labelledby="customized-dialog-title" open={this.state.renameDocument}>
          <Box>
            <IconButton aria-label="close" data-test-id="closeRenameDialog" style={{ float: "right" }} onClick={this.handleRenameDialogClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent >
            <Box sx={webStyles.formRenameDocument}>
              <Grid container sx={webStyles.inputContainer}>
                <div style={{ width: '100%' }}>
                  <div style={webStyles.headImgRenameDocument}>
                    {<img src={logo} />}
                  </div>
                </div>
                <Box style={webStyles.containerLabelInputNumber}>
                  <Typography style={webStyles.head1}>
                    Renaming
                  </Typography>
                </Box>
                <Box style={webStyles.containerLabelInputNumber}>
                  <Typography style={webStyles.head3}>
                    Please rename your document while filling in the filed below
                  </Typography>
                </Box>

                <Grid item xs={12}>
                  <div>
                    <div style={webStyles.inputLabelHeading}>Document information</div>
                    <div style={webStyles.inputLabel}>Document Name</div>
                    <TextField
                      data-test-id="documentNameField"
                      name='documentNameField'
                      placeholder="Medical card"
                      variant="outlined"
                      value={this.state.documentName}
                      fullWidth 
                      margin="normal"
                      required 
                      style={webStyles.phoneInput}
                      onChange={(e: any) => {this.handleDocumentName(e)}}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={6} lg={5}>
                      <Button
                        data-test-id="renameDialogContinue"
                        variant={"outlined"}
                        sx={{ textTransform: 'capitalize', fontFamily: "Inter" }}
                        style={webStyles.continueBtn}
                        onClick={()=>this.renameDocApi(this.state.selectedItem.wallet.id)} >
                        Continue
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={5}>
                      <Button
                        data-test-id="renameDialogCancel"
                        variant={"outlined"}
                        sx={{ textTransform: 'capitalize', fontFamily: "Inter" }}
                        style={webStyles.cancelBtn}
                        onClick={this.handleRenameDialogClose}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box >
          </DialogContent>
        </Dialog>

        <Dialog onClose={this.handleDeleteDialogClose} maxWidth={"lg"} aria-labelledby="customized-dialog-title" open={this.state.deleteDocument}>
          <Box>
            <IconButton aria-label="close" data-test-id="closeDeleteDialog" style={{ float: "right" }} onClick={this.handleDeleteDialogClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent >
            <Box sx={webStyles.formDeleteDocument}>
              <Grid container sx={webStyles.inputContainer}>
                <Box style={webStyles.containerLabelInputNumber}>
                  <Typography style={webStyles.deleteHead1}>
                    Deleting Document
                  </Typography>
                </Box>
                <Box style={webStyles.containerLabelInputNumber}>
                  <Typography style={webStyles.deleteHead3}>
                  Are you sure you want to delete this document?
                  </Typography>
                </Box>

                <Grid item xs={12} lg={12} >
                  <Grid container spacing={2} justifyContent="space-between" alignItems="center" marginTop="20px">
                    <Grid item xs={12} sm={6} lg={5}>
                      <Button
                        data-test-id="deleteDialogContinue"
                        variant={"outlined"}
                        sx={{ textTransform: 'capitalize', fontFamily: "Inter" }}
                        style={webStyles.cancelDeleteBtn}
                        onClick={this.handleDeleteDialogClose}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={5}>
                      <Button
                        data-test-id="deleteDialogCancel"
                        variant={"outlined"}
                        sx={{ textTransform: 'capitalize', fontFamily: "Inter" }}
                        style={webStyles.deleteBtn}
                        onClick={()=>this.deleteDocApi(this.state.selectedItem.wallet.id)}
                        >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box >
          </DialogContent>
        </Dialog>

        <Dialog data-test-id="deleteSuccessDialog" onClose={this.handleDeleteSuccessDialogClose} maxWidth={"lg"} aria-labelledby="customized-dialog-title" open={this.state.deleteSuccessDocument}>
          <DialogContent >
              <div style={webStyles.headImgDeleteDocument}>
                    {<img src={logo} />}
              </div>
            <Box sx={webStyles.formDeleteSuccessDocument}>
              <Grid container sx={webStyles.inputContainer}>
                <div style={{ width: '100%' }}>
                  <div style={webStyles.doneImgDeleteDocument}>
                    {<img src={done} />}
                  </div>
                </div>
                <Box style={webStyles.containerLabelInputNumber}>
                  <Typography style={webStyles.deleteHead1}>
                    Completed
                  </Typography>
                </Box>
                <Box style={webStyles.containerLabelInputNumber}>
                  <Typography style={webStyles.deleteHead3}>
                    Your document was deleted successfully
                  </Typography>
                </Box>
              </Grid>
            </Box >
          </DialogContent>
        </Dialog>
        </>
      </Layout>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const webStyles = {
  body: {
    height: "100%",
    width: "100%"
  },
  ImgTagStyle: {
    width: '100px',
    height: '100px',
    padding: '10px'
  },
  card: {
    width: "200px",
    height: "311px",
    opacity: "0px",
    background: "#F1F5F9",
    borderRadius: "16px",
    border: "8px solid #E2E8F0",
  },
  container: {
    gap: "24px",
  },
  cardImg: {
    width: "100%",
    height: "168px",
    borderRadius: "8px 8px 0 0",
  },
  labelContainer: {
    padding: "6.5px",
  },
  cardHeading: {
    color: "#334155",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "26px",
    fontFamily: "Inter",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: 2,
    textOverflow: "ellipsis"
  },
  cardSubHeading: {
    color: "#334155",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Inter"
  },
  pageHeading: {
    fontSize: "clamp(0.5rem, 5vw, 2rem)",
    fontWeight: 700,
    lineHeight: "32px",
    fontFamily: "Inter"
  },
  newDocCard: {
    width: "200px",
    height: "62px",
    background: "#F1F5F9",
    borderRadius: "16px",
    border: "8px solid #E2E8F0",
    position: "relative",
    margin: "2% 0"
  },
  gridHeading: {
    fontSize: "clamp(0.5rem, 7vw, 2.5rem)",
    fontWeight: 700,
    lineHeight: "44px",
    margin: "3% 0",
    fontFamily: "Inter"
  },
  paginationBox: {
    justifyContent: "center",
    display: "flex"
  },
  vertDotsContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  vertDotBtn: {
    cursor: "pointer",
    padding: "10px"
  },
  newDoclabelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
  },
  addDocHeading: {
    color: "#334155",
    fontWeight: 400,
    fontSize: "14px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
  },
  iconWrapper: {
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: '24px',
    height: '24px',
  },
  menuItem: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textAlign: "right",
    color: "#475569",
    borderBottom: "1px solid #CBD5E1",
  },
  menu: {
    border: "1px solid #CBD5E1"
  },
  dialogImage: {
    width: "100%",
    maxWidth: "580px",
    height: "100%",
    maxHeight: "580px",
    borderRadius: "8px",
    border: "20px solid #F1F5F9",
    margin:"0px 24px",
  },
  containerErrorRegisteredUser: {
    width: "100%",
    display: 'flex',
    justifyContent: "center"
  },
  containerLabelInputNumber: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  rootForgotPassword: {
    display: 'flex',
    alignItems: 'center',
    height: "26px",
    width: '71px',
    marginTop: '58px',
    marginLeft: '34px',
  },
  arrowForgotPassword: {
    color: '#0F172A'
  },
  backBtnForgotPassword: {
    fontSize: '18px',
    color: '#0F172A',
    fontFamily: "Inter"
  },
  formRenameDocument: {
    heigth: '100%',
    maxHeight:"524px",
    maxWidth:"783px",
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: { xs: '30px', md: '50px', lg: '80px' },
    marginTop: '50px',
    marginBottom: '20px',
    borderRadius: "16px"
  },
  formDeleteDocument: {
    // heigth: '100%',
    maxHeight:"327px",
    maxWidth:"562px",
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: { xs: '30px', md: '50px', lg: '80px' },
    marginTop: '50px',
    marginBottom: '20px',
    borderRadius: "16px"
  },
  formDeleteSuccessDocument:{
    // heigth: '100%',
    maxHeight:"295px",
    maxWidth:"435px",
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: { xs: '30px', md: '50px', lg: '80px' },
    marginTop: '50px',
    marginBottom: '20px',
    borderRadius: "16px"
  },
  headImgBoxForgotPassword: {
    with: '100%',
    heigth: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
  },
  headImgRenameDocument: {
    margin: "0 auto",
    height: '80px',
    width: '70px',
  },
  doneImgDeleteDocument: {
    margin: "0 auto",
    height: '50px',
    width: '50px',
  },
  headImgDeleteDocument: {
    height: '48px',
    width: '48px',
  },
  head1:{
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    textAlign: "center",
  },
  head3: {
    marginBottom: '20px',
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    textAlign: "center",
  },
  inputContainer: {
    heigth: '100%',
    width: '100%',
    padding: { xs: '0 50px 0 0px', md: '0 70px 0 0px', lg: '0 105px 0 0px' },
  },
  inputLabel: {
    fontSize: '14px',
    display: 'flex',
    fontWeight: '700',
    fontFamily: "Inter",
    lineHeight: "22px",
    color:"#64748B"
  },
  inputLabelHeading:{
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    textAlign: "left",
    color:"#64748B"
  },
  phoneInput: {
    height: '56px',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: '400'
  },
  continueBtn: {
    width: '100%',
    height: '56px',
    fontSize: '15px',
    color: 'white',
    background: 'linear-gradient(to right, #FCD34D, #D97706 )',
    marginTop: '8px',
    border: 'none',
  },
  cancelBtn:{
    width: '100%',
    height: '56px',
    fontSize: '15px',
    color: 'black',
    marginTop: '8px',
    border:"none",
    background: "#F1F5F9",
  },
  continueBtnBlock: {
    width: '100%',
    height: '56px',
    fontSize: '15px',
    background: '#F1F5F9',
    marginTop: '8px',
    border: 'none',
    color: '#64748B',
  },
  deleteHead1:{
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "center",
    color:"#DC2626"
  },
  deleteHead3:{
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "center",
    color:"#64748B",
  },
  cancelDeleteBtn:{
    width: '100%',
    height: '56px',
    fontSize: '15px',
    color: 'white',
    background: 'linear-gradient(to right, #FCD34D, #D97706 )',
    marginTop: '8px',
    border: 'none',
  },
  deleteBtn:{
    width: '100%',
    height: '56px',
    fontSize: '15px',
    color: '#F87171',
    marginTop: '8px',
    border:"none",
    background: "#F1F5F9",
  }
}

const StyledImgForgotPassword = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  marginTop: '42px',
  marginBottom: '40px',
  [theme.breakpoints.up('md')]: {
    height: '73%',
    borderRadius: '64px 0px 0px 64px',
  },
}));
// Customizable Area End
