// Customizable Area Start
import React from "react";
import Subscriptionbilling2Controller, {
    Props,
} from "./Subscriptionbilling2Controller";
import { Box, Card, CardContent, Stack, styled, Button, Typography, Grid } from '@mui/material';
import Layout from "../../landingpage/src/Layout.web";
import { logoIcon , visaCard } from './assets';
import {setStorageData } from "../../../framework/src/Utilities";

const Logo = styled('img')({
    width: '40px'
});

export default class PaymentConfirmation extends Subscriptionbilling2Controller {
    constructor(props: Props) {
        super(props);

    }

    render() {
        return (
            <Layout id="" navigation={this.props.navigation}>
                <Box sx={{ margin: 'auto', padding: '20px', fontFamily: 'Inter' }}>
                    <Typography variant="h5" sx={{ fontFamily: 'Inter', fontSize: '24px', fontWeight: 700 }} gutterBottom>
                        Subscription Billing
                    </Typography>
                    <Typography variant="h6" sx={{ fontFamily: 'Inter', fontSize: '20px', fontWeight: 700, color: '#475569', paddingTop: '30px' }}>Payment Confirmation</Typography>
                    <Typography sx={{ fontFamily: 'Inter', fontSize: '20px', fontWeight: 700, color: '#475569', paddingTop: '20px', display: 'flex', justifyContent: 'center' }}>Do you confirm your purchase?</Typography>
                    <Box sx={{paddingLeft:{sx:'0px' , sm:'180px'}, paddingRight:{sx:'0px' , sm:'180px'} , paddingTop:'20px'}}>
                        <Grid container spacing={3}>
                            <Grid item xs={5} >
                                <Typography sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 700, color: '#475569' }}>Your payment method</Typography>
                                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center' , gap:5 , borderRadius:'16px' , border: '1px solid grey'}}> 
                                    <img
                                        src={visaCard.default}
                                        alt="Card icon"
                                        style={{ width: '67px', height: '39px' }}
                                    />
                                    <p style={{fontSize: '18px', fontWeight: 700, fontFamily: 'Inter' , color:'#0F172A'}}>**** **** **** 2917</p>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 700, color: '#475569' }}>Your subscription plan</Typography>
                                <Card style={{ width: '444px', padding: '5px', borderRadius: '16px', borderColor: '#CBD5E1', borderWidth: '10px',marginTop:'25px' }}>
                                    <CardContent>
                                        <Box display="flex" justifyContent="center" marginBottom="16px">
                                            <Stack sx={{ ml: 1, display: { width: '80px' }, paddingLeft: '25px' }}>
                                                <Logo src={logoIcon.default} />
                                            </Stack>
                                        </Box>
                                        <h3 style={{ textAlign: 'center', margin: '8px 0', fontSize: '24px', fontWeight: 400, fontFamily: 'Inter' }}>Subscription Plan</h3>
                                        <p style={{ textAlign: 'center', margin: '8px 0', fontSize: '14px', fontWeight: 400, fontFamily: 'Inter' }}>
                                            Advantage PHC provides you with an all-inclusive yearly plan
                                        </p>

                                        <Box margin='10px' >
                                            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                                <li style={{ fontSize: '16px', fontWeight: 400, color: '#334155', fontFamily: 'Inter', marginBottom: '6px' }}>$99/year Plan</li>
                                                <li style={{ fontSize: '16px', fontWeight: 400, color: '#334155', fontFamily: 'Inter', marginBottom: '6px' }}>Access to Personal Healthcare Navigators</li>
                                                <li style={{ fontSize: '16px', fontWeight: 400, color: '#334155', fontFamily: 'Inter', marginBottom: '6px' }}>Claims Explanation & Support</li>
                                                <li style={{ fontSize: '16px', fontWeight: 400, color: '#334155', fontFamily: 'Inter', marginBottom: '6px' }}>Procedure Cost Estimates</li>
                                                <li style={{ fontSize: '16px', fontWeight: 400, color: '#334155', fontFamily: 'Inter', marginBottom: '6px' }}>Medical Bill Reviews and Negotiation</li>
                                                <li style={{ fontSize: '16px', fontWeight: 400, color: '#334155', fontFamily: 'Inter', marginBottom: '6px' }}>Direct Pay Network & Affordable Labs and Imaging</li>
                                                <li style={{ fontSize: '16px', fontWeight: 400, color: '#334155', fontFamily: 'Inter', marginBottom: '6px' }}>Provider search</li>
                                                <li style={{ color: '#334155', fontSize: '16px', fontWeight: 400, fontFamily: 'Inter' }}>Renews on: 12/03/2024 </li>
                                            </ul>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Box sx={{display: 'flex' , justifyContent:'space-between' , borderBottom:'1px solid gray', height:'65px', paddingLeft:'20px' , paddingRight:'20px' }} >
                        <p style={{ fontSize: '20px', fontWeight: 700, fontFamily: 'Inter' }}>
                        In Total:
                        </p>
                        <p style={{ fontSize: '24px', fontWeight: 700, fontFamily: 'Inter' }}>
                            $99
                        </p>
                        </Box>
                    </Box>
                    <Box sx={{display:'flex', justifyContent:'center' , paddingTop:'40px'}}>
                    <Box sx={{width:'50%', display:'flex',gap:8 }}>
                    <Button 
                       data-test-id='submitBtn'
                      fullWidth
                      onClick={()=>{
                        setStorageData('isShowProgress' , true) 
                        this.props.navigation.navigate('LandingPage')
                       } }
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                          background: 'linear-gradient(90deg, #f7b733, #dd8723)',
                          fontWeight: 600,
                          textTransform: 'none',
                          fontFamily: 'Inter',
                        }}
                      >
                        Confirm
                      </Button>
                      <Button 
                      data-test-id='cancelBtn'
                      onClick={()=>{this.props.navigation.navigate('AddSubscription')}}
                        fullWidth
                        variant="contained"
                        sx={{
                          background: '#f1f4f9',
                          fontWeight: 600,
                          textTransform: 'none',
                          fontFamily: 'Inter',
                          color: '#788393',
                          ":hover": {
                            background: '#f1f4f9',
                          },
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                    </Box>
                </Box>
            </Layout>
        );
    }
}

// Customizable Area End
