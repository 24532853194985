import React from "react";
// Customizable Area Start
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  Typography,
  Box,
  Stack,
  FormLabel,
  Button,
  styled
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
} from "@mui/material/styles";
import "@mui/styles";
const DatePicker = require("react-datepicker");

import "react-datepicker/dist/react-datepicker.css";

const drawerWidth = 120;
const {userProfileObj} = require("./config");

import Header from "./components/Header";
import EditUserProfile from "./components/EditUserProfile";
import AddNewDependant from "./components/AddNewDependant";
import EmailPhoneVerificationModal from "./components/EmailPhoneVerificationModal";
import DeleteDependantModal from "./components/DeleteDependantModal";
import DependantAlert from "./components/DependantAlert";
import HeaderbtnGroup from "./components/HeaderBtnGroup";
import { MuiTelInput } from "mui-tel-input";
import {Action, VerificationEnum} from "./CustomisableUserProfilesController"

const {Theme: styledTheme} = require("./theme");
const { InfoBox, Card } = styledTheme;
import Layout from "../../landingpage/src/Layout.web";
import { ToastContainer } from "react-toastify";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  IField,
} from "./CustomisableUserProfilesController";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    ) {
      return "number";
    }
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    ) {
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.value,
                field.field_type
              )
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true,
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );
    }

    if (field.field_type === "date" || field.field_type === "datetime") {
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(
                  this.state.profile[field.name] as string
                ).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date: Date) =>
                this.changeFormValue(field.name, date, field.field_type)
              }
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );
    }

    if (field.field_type === "checkbox") {
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.checked,
                field.field_type
              )
            }
            color="primary"
          />
          {field.title}
        </div>
      );
    }
    if (field.field_type === "boolean") {
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(
                  field.name,
                  event.target.value,
                  field.field_type
                );
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel
                value={"true"}
                control={<Radio />}
                label="True"
              />
              <FormControlLabel
                value={"false"}
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
          </FormControl>
        </div>
      );
    }
  };

  render() {
    // Customizable Area Start
    const getContent = (content: { title: string, value: string }[]) => {
      return (
        <>
        {content.map((item, indx) => (
          <Stack className='infoBox' key={indx}>
            <Typography className="infoBox-title">{item.title}</Typography>
            <Typography className="infoBox-data">{item.value}</Typography>
          </Stack>
        ))}
        </>
      )
    }
    return (
        <ThemeProvider theme={theme}>
            {/* Customizable Area Start */}
              <Layout id="" navigation={this.props.navigation}>
              <ToastContainer />
              <Box sx={{ display: "flex" }}>
                <Box component="main" sx={{ flexGrow: 1, px: 1, py: 2, width: { sm: `calc(100% - ${drawerWidth}px)` }}}>
                  <UserProfile>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Typography sx={{color:'black'}} className="page-title">{userProfileObj.pageTitleTxt}</Typography>
                      <HeaderbtnGroup
                        isVisible="not-visible"
                        profileAction={this.state.profileAction}
                        disabled={this.state.isDisabled} 
                        onSaveNewDependant={this.onSaveNewDependant} 
                        userProfileObj={userProfileObj} 
                        onCancelClick={this.onCancelClick} 
                        onEditClick={this.onEditClick} 
                        saveUserProfile={this.saveUserProfile} 
                        btnTxt={this.state.headerBtnTxt} 
                        />
                    </Stack>
                    <Box className="main-content">
                      <Header
                        btnTxt={this.state.headerBtnTxt} 
                        userName={this.state.avatarName} 
                        userAbbr={this.state.avatarAbbr} 
                        profileAction={this.state.profileAction}
                        onEditClick={this.onEditClick}
                        onCancelClick={this.onCancelClick}
                        saveUserProfile={this.saveUserProfile}
                        disabled={this.state.isDisabled}
                        onSaveNewDependant={this.onSaveNewDependant}
                      />
                    {((this.state.profileAction !== Action.EDIT_PROFILE) && (this.state.profileAction !== Action.CREATE_DEPENDANT) && (this.state.profileAction !== Action.EDIT_DEPENDANT)) && 
                      (<Stack justifyContent={"space-between"} direction={"row"} className="info-stack">
                      <Stack gap={"64px"} className='gap-64'>
                          <InfoBox gap={"16px"}>
                              <Typography component={"h1"} className="infoBox-headline">{userProfileObj.personalInfoTxt}</Typography>
                              <Stack gap={"64px"} style={{ flexDirection: "row" }} className='responsive-stack'>
                                  {getContent([
                                    {title:userProfileObj.firstNameTxt, value:this.state.userDetails.firstName}, 
                                    {title:userProfileObj.lastNameTxt, value:this.state.userDetails.lastName}, 
                                    {title:userProfileObj.birthDateTxt, value:this.state.userDetails.birthDate}
                                  ])}
                              </Stack>
                          </InfoBox>
                          <InfoBox gap={"16px"}>
                              <Typography component={"h1"} className="infoBox-headline">{userProfileObj.contactTxt}</Typography>
                              <Stack gap={"64px"} style={{ flexDirection: "row" }} className='responsive-stack'>
                                  <Stack className='infoBox' gap={"4px"}>
                                    <Typography className="infoBox-title">{userProfileObj.phoneNumberTxt}</Typography>
                                    <MuiTelInput value={this.state.userDetails.phoneNumber} defaultCountry="US" className="infoBox-data" slotProps={{input: {padding: 0, color: "#1E293B", fontSize: "14px"}}} />
                                  </Stack>
                                  {getContent([
                                    {title:userProfileObj.emailTxt, value:this.state.userDetails.email}, 
                                  ])}
                              </Stack>
                          </InfoBox>
                          <InfoBox gap={"16px"}>
                              <Typography component={"h1"} className="infoBox-headline">{userProfileObj.phpMembershipTxt}</Typography>
                              <Stack gap={"64px"} style={{ flexDirection: "row" }} className='responsive-stack'>
                                  {getContent([
                                    {title:userProfileObj.participantIdTxt, value:this.state.userDetails.participantId}, 
                                  ])}
                              </Stack>
                          </InfoBox>
                      </Stack>
                      <InfoBox gap={"8px"}>
                          <Typography component={"h1"} className="infoBox-headline" style={{ color: "#334155" }}>{userProfileObj.dependantsTxt}</Typography>
                          <Stack gap={"14px"}>
                              {this.state.dependantsArray?.map(item => (
                                  <Card key={item.id}>
                                      <div className="card-header">
                                          <Typography component={"h1"} className="headline" style={{ fontSize: "15px" }}>{item.title}</Typography>
                                          <Stack direction={"row"} gap={"6px"}>
                                              <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
                                                  <span style={{color: "#475569", fontWeight: 400, fontSize: "14px"}} className='not-visible'>Edit</span>
                                                  <div className='svg' onClick={() => this.onEditDepandantClick(item.id)}>
                                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M1.33333 10.6667H2.28333L8.8 4.15L7.85 3.2L1.33333 9.71666V10.6667ZM0 12V9.16666L8.8 0.383333C8.93333 0.261111 9.08066 0.166667 9.242 0.1C9.40333 0.0333334 9.57266 0 9.75 0C9.92733 0 10.0996 0.0333334 10.2667 0.1C10.4338 0.166667 10.5782 0.266667 10.7 0.4L11.6167 1.33333C11.75 1.45555 11.8473 1.6 11.9087 1.76667C11.97 1.93333 12.0004 2.1 12 2.26667C12 2.44444 11.9696 2.614 11.9087 2.77533C11.8478 2.93667 11.7504 3.08378 11.6167 3.21667L2.83333 12H0ZM8.31666 3.68333L7.85 3.2L8.8 4.15L8.31666 3.68333Z" fill="#475569" />
                                                      </svg>
                                                  </div>
                                              </Stack>
                                              <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
                                                  <span style={{color: "#475569", fontWeight: 400, fontSize: "14px"}} className='not-visible'>Delete</span>
                                                  <div className='svg' onClick={() => this.handleDeleteDependantOpen(item.id)}>
                                                      <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M6.41203 4.98L4.9987 6.39333L3.5787 4.98L2.6387 5.92L4.0587 7.33333L2.64536 8.74667L3.58537 9.68667L4.9987 8.27333L6.41203 9.68667L7.35203 8.74667L5.9387 7.33333L7.35203 5.92L6.41203 4.98ZM7.33203 0.666667L6.66537 0H3.33203L2.66536 0.666667H0.332031V2H9.66537V0.666667H7.33203ZM0.998698 10.6667C0.998698 11.4 1.5987 12 2.33203 12H7.66537C8.3987 12 8.9987 11.4 8.9987 10.6667V2.66667H0.998698V10.6667ZM2.33203 4H7.66537V10.6667H2.33203V4Z" fill="#DC2626" />
                                                      </svg>
                                                  </div>
                                              </Stack>
                                          </Stack>
                                      </div>
                                      <Stack gap={"6px"} className="card-body">
                                          <div>
                                              <Typography component={"h1"} className="infoBox-data" fontSize={"16px"} style={{ color: "#1E293B" }}>{`${item.firstName} ${item.lastName}`}</Typography>
                                          </div>
                                          <Stack className='dependant-details' gap={"6px"}>
                                              <div>
                                                  <Typography component={"h1"} className="label-style">{"Birthdate:"}</Typography>
                                                  <Typography component={"h1"} className="infoBox-data data-style">{item.birthDate}</Typography>
                                              </div>
                                              <div>
                                                  <Typography component={"h1"} className="label-style">{"Relationship:"}</Typography>
                                                  <Typography component={"h1"} className="infoBox-data data-style" style={{textTransform: "capitalize"}}>{item.relationship}</Typography>
                                              </div>
                                          </Stack>
                                      </Stack>
                                  </Card>
                              ))}
                              <Button className="add-new-btn" onClick={() => this.onAddNewDepandantClick()}>
                                  <Typography>{userProfileObj.addNewBtnTxt}</Typography>
                                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M11 0.4375C5.176 0.4375 0.4375 5.176 0.4375 11C0.4375 16.824 5.176 21.5625 11 21.5625C16.824 21.5625 21.5625 16.824 21.5625 11C21.5625 5.176 16.824 0.4375 11 0.4375ZM11 2.0625C15.9457 2.0625 19.9375 6.05431 19.9375 11C19.9375 15.9457 15.9457 19.9375 11 19.9375C6.05431 19.9375 2.0625 15.9457 2.0625 11C2.0625 6.05431 6.05431 2.0625 11 2.0625ZM10.1875 6.125V10.1875H6.125V11.8125H10.1875V15.875H11.8125V11.8125H15.875V10.1875H11.8125V6.125H10.1875Z" fill="#475569" />
                                  </svg>
                              </Button>
                          </Stack>
                      </InfoBox>
                      </Stack>)
                    }
                    <div className="column-stack">
                      {(this.state.profileAction === Action.EDIT_PROFILE) && <EditUserProfile
                          userDetails={this.state.userDetails} 
                          errorMessages={this.state.errorMessages}
                          userInput={this.state.updateUserData} 
                          inputErrors={this.state.inputErrors} 
                          handleInput={this.handleInputChange} />}
                      {(this.state.profileAction === Action.CREATE_DEPENDANT || (this.state.profileAction === Action.EDIT_DEPENDANT)) && <AddNewDependant 
                          userInput={this.state.dependantData} 
                          inputErrors={this.state.inputDependantErrors}
                          errorMessages={this.state.errorMessages}
                          handleInput={this.handleDependantInputChange} />}
                    </div>
                    </Box>
                  </UserProfile>
                </Box>
              </Box>
                <EmailPhoneVerificationModal 
                  open={this.state.isVerificationOpen} 
                  onClose={this.handleVerificationClose} 
                  onClickVerify={this.handlePhoneVerifyButton}
                  heading={userProfileObj.dataVerificationTxt}
                  title={this.refactorTitleOfModal()} 
                  btnTitle1={this.state.verificationType === VerificationEnum.PHONE_VERIFICATION ? userProfileObj.phoneVerifyBtnTxt : userProfileObj.emailVerifyBtnTxt} 
                  btnTitle2={userProfileObj.cancelTxt}
                />
                {this.state.isDeleteDepandantOpen && <DeleteDependantModal open={this.state.isDeleteDepandantOpen} onClose={this.handleDeleteDependantClose} onClickDelete={this.onDeleteDependant} />}
                {this.state.isDepandantAlertOpen && <DependantAlert open={this.state.isDepandantAlertOpen} onClose={this.handleAlertDependantClose} />}
              </Layout>
            {/* Customizable End Start */}
          </ThemeProvider>
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const UserProfile = styled(Stack)({
  boxSizing: "border-box",
  gap: "18px",
  

  ".MuiTextField-root": {
    margin: "0"
  },
  ".responsive-stack .MuiInputAdornment-root": {
    display: "none !important"
  },
  ".responsive-stack .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
    padding: "0 !important"
  },
  ".responsive-stack .MuiInputBase-inputAdornedStart": {
    padding: "0 !important",
    fontSize: "14px !important",
    color: "#1E293B !important"
  },
  ".responsive-stack .MuiInputBase-adornedStart": {
    padding: "0 !important",
  },
  ".column-stack .MuiInputAdornment-root": {
    display: "none"
  },
  ".column-stack .MuiOutlinedInput-input": {
    padding: 0,
    color: "currentColor"
  },
  ".column-stack .MuiInputBase-inputAdornedEnd": {
    padding: 0
  },
  ".column-stack .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    padding: 0
  },
  ".column-stack .MuiInputBase-inputAdornedStart": {
    padding: 0,
    fontSize: "14px",
  },
  ".column-stack .MuiInputBase-adornedStart": {
    padding: "8px",
    height: "56px !important",
  },
  ".column-stack .MuiSelect-outlined": {
    padding: "8px"
  },
  "& .visible": {
    display: "block"
  },
  "& .not-visible": {
      display: "none"
  },
  "& .pl-2": {
    paddingLeft: "2rem"
  },
  "& .page-title": {
      color: "#000",
      fontFamily: "Inter",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: 0,
  },
  "& .main-content": {
      padding: "0 0 0 64px",
      display: "flex",
      flexDirection: "column",
      gap: "18px"
  },
  "& .info-stack": {
      width: "calc(100% - 100px)",
      padding: "0 0 0 10%"
  },
  "& .btn": {
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "Inter",
    textTransform: "initial",
    letterSpacing: 0,
    height: "44px",
    borderRadius: "8px"
  },
  "& .edit-btn": {
      background: `linear-gradient(99.09deg, #FCD34D 2.64%, #D97706 100%)`,
      color: "#fff",
      minWidth: "117px",
  },
  "& .cancel-btn": {
      background: `#E2E8F0`,
      color: "#64748B",
      maxWidth: "87px",
      padding: "10px 16px 10px 16px"
  },
  "& .finish-btn": {
      background: `linear-gradient(99.09deg, #93C5FD 2.64%, #2563EB 100%)`,
      color: "#fff",
      maxWidth: "79px",
      padding: "10px 16px 10px 16px"
  },
  "@media(max-width:1199px)": {
    "& .pl-2": {
      paddingLeft: "0"
    },
  },
  "@media(max-width: 1140px)": {
    ".btn-group": {
      gap: "12px"
    },
    "& .btn": {
      fontSize: "14px"
    },
    "& .edit-btn": {
        width: "107px",
    },
    "& .cancel-btn": {
        width: "77px",
    },
    "& .finish-btn": {
        width: "69px",
    },
  },
  "@media(max-width: 1070px)": {
    ".visible": {
      display: "none"
    },
    ".not-visible": {
      display: "block"
    },
    ".info-stack": {
      flexDirection: "column",
      gap: "40px"
    },
    ".gap-64": {
      gap: "40px"
    },
    "& .pl-2": {
      paddingLeft: "0"
    },
  },
  "@media(max-width:931px)": {
    ".btn-group": {
      gap: "12px"
    }
  },
  "@media(max-width:920px)": {
    ".main-content": {
      padding: 0
    },
    ".infoBox": {
      flexDirection: "row !important",
      alignItems: "center",
      justifyContent: "space-between"
  }},
  "@media(max-width:739px)": {
    ".info-stack": {
      width: "100%",
      padding: 0
    },
    ".dependant-block": {
      maxWidth: "100% !important",
      marginTop: "0 !important",
      padding: "0 !important",
      "& > div": {
        padding: "0 !important"
      }
    },
  }
})

// Customizable Area End
