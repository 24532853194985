import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { imgPhcPic, successSvg } from "./assets"
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";

export const OTPMessages = {
  otpValidationError: "Incorrect code, please try again",
  codeSentSuccess: "The code was sent successfully",
}

export enum Actions {
  SUBSCRIPTION_BILLING,
  TWO_FACTOR_AUTHENTICATION,
  CHANGE_PASSWORD,
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
  DELETE_ACCOUNT
}
export enum AuthenticationMethod {
  EMAIL,
  PHONE
}
export interface ModalType { 
  header: {title: string, color?: string}, description?: any, icon: boolean, svg?: any, btn1: {
  title: string,
  visible?: boolean,
  color?: string
}, btn2: {
  title: string,
  visible?: boolean,
  color?: string,
  backgroundColor?: string
},
stage?: "first" | "last"
}
export interface VerifyModalType { 
  title: string, description: any,
  userData: string,
  btn: {
    title: string
  }}
export interface InputErrors{newPasswordError: boolean, oldPasswordError: boolean, confirmNewPasswordError: boolean}
export interface ErrorMessages{newPasswordErrorMsg: string, oldPasswordErrorMsg: string, confirmNewPasswordErrorMsg: string}

const initialModalData: ModalType = {
  stage: "first",
  header: {
    title: "",
    color: ""
  },
  icon: false,
  btn1: {
    title: "",
    visible: true,
    color: ""
  },
  btn2: {
    title: "",
    visible: true,
    color: "",
    backgroundColor: ""
  }
}
const initialVerifyModalData: VerifyModalType = {
  title: "",
  description: undefined,
  userData: "",
  btn: {
    title: ""
  }
}
type CurrentModalType = {
  "update-payment": boolean;
  "card-verification": boolean;
  "payment-deduction": boolean;
  "payment-processed": boolean;
  "payment-success": boolean;
  "payment-fail": boolean;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  phcPic: any;
  action: { type: Actions, active: boolean },
  isTermsModalOpen: boolean,
  isModalOpen: boolean,
  isVerifyModalOpen: boolean,
  btnContinueBlockStatus: boolean,
  AuthMethod: AuthenticationMethod,
  termsData: { title: string, data: any },
  modalData: ModalType,
  verifyModalData: VerifyModalType,
  inputErrors: InputErrors;
  errorMessages: ErrorMessages;
  new_password: string;
  old_password: string;
  confirm_password: string;
  phone_number: string;
  email: string;

  currentModal: CurrentModalType;

  otp: string;
  tab: boolean;
  isMobileScreen: boolean;
  formatedPhoneNumber: any;
  otpError: boolean;
  resendOtpSent: boolean;
  otpErrorMessages: {
    otpValidationError: string,
    codeSentSuccess: string
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsPolicyCallID: string = "";
  getUserProfileCallId: string = "";
  requestUpdateNewPasswordCallId: string = "";
  getAuthMethodCallId: string = "";
  changePasswordMethodCallId: string = "";
  updateAuthMethodCallId: string = "";
  deleteAccountCallId: string = "";
  deleteotpCallId: string = "";
  resendCodeCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      phcPic: imgPhcPic.default,
      action: { type: Actions.SUBSCRIPTION_BILLING, active: true },
      isTermsModalOpen: false,
      isModalOpen: false,
      isVerifyModalOpen: false,
      AuthMethod: AuthenticationMethod.EMAIL,
      termsData: {
        title: "",
        data: null
      },
      modalData: initialModalData,
      verifyModalData: initialVerifyModalData,
      inputErrors: {
        newPasswordError: false,
        oldPasswordError: false,
        confirmNewPasswordError: false
      },
      errorMessages: {
        newPasswordErrorMsg: "",
        oldPasswordErrorMsg: "",
        confirmNewPasswordErrorMsg: ""
      },
      new_password: "",
      old_password: "",
      confirm_password: "",
      btnContinueBlockStatus: false,
      otp: "",
      otpError: false,
      resendOtpSent: false,
      formatedPhoneNumber: "",
      phone_number: "",
      email: "",
      otpErrorMessages: OTPMessages,
      tab: true,
      isMobileScreen: false,

      currentModal: {
        "update-payment": false,
        "card-verification": false,
        "payment-deduction": false,
        "payment-processed": false,
        "payment-success": false,
        "payment-fail": false
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    this.handleGetUserProfileResponse(apiRequestCallId, message)
    this.handleResendCodeResponse(apiRequestCallId, message)
    this.handleGetTermsResponse(apiRequestCallId, message)
    this.handleUpdateAuthResponse(apiRequestCallId, message)
    this.handleGetAuthResponse(apiRequestCallId, message)
    this.handleChangePasswordResponse(apiRequestCallId, message)
    this.handleDeleteAccountResponse(apiRequestCallId, message)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  onClickUpdatePayment = (type: keyof CurrentModalType, flag: boolean = true) => {
    let object = this.state.currentModal;
    let key: keyof CurrentModalType;
    for (key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key) && key !== type) {
        object[key] = false
      }
    }
    
    this.setState({
      currentModal: {...object, [type]: flag}
    })
  }
  onTabClick = () => {
    this.setState({tab: !this.state.tab})
  }
  async componentDidMount() {
    super.componentDidMount();

    if(!localStorage.getItem("token")) {
      this.redirectToSignInPage()
    }
    this.getAuthMethod()
    this.getCustomizableProfile()

    this.setState({isMobileScreen: window.innerWidth <= 991}, () => this.setState({tab: !this.state.isMobileScreen}))
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if(prevState.isMobileScreen !== this.state.isMobileScreen) {
      window.addEventListener("resize", () => this.setState({isMobileScreen: window.innerWidth <= 991 ? true : false}, () => this.setState({tab: !this.state.isMobileScreen})))
    }
  }
  changeAction = (action: Actions) => {
    let isTermsModalOpen = false;
    let tab = false;

    switch(action) {
      case Actions.DELETE_ACCOUNT:
        tab = true;
        this.setState({isModalOpen: true, modalData: {
          stage: "first",
          header: {
            title: "Account Deleting",
          },
          description: "Are you sure you want to delete your account?",
          icon: false,
          btn1: {
            title: "Cancel",
            visible: true
          },
          btn2: {
            title: "Delete account",
            visible: true
          }
        }});
        break;
      default:
        break;
    }
    if(Actions.TERMS_AND_CONDITIONS === action || Actions.PRIVACY_POLICY === action) {
      tab = true;
      isTermsModalOpen = true;
    }
    this.setState({ tab: tab || !this.state.isMobileScreen, action: { type: action, active: true }, termsData: {
      title: "",
      data: null
    },
    isTermsModalOpen: isTermsModalOpen}, () => {
      if (isTermsModalOpen) {
        this.getTermsAndPolicy()
      }
    })
  }
  changeAuthMethod = (auth: AuthenticationMethod) => {
    this.setState({AuthMethod: auth});
  }
  onClickCancel = () => {
    this.setState({action: {active: true, type: Actions.SUBSCRIPTION_BILLING}})
  };
  handleTermsModal = (isOpen: boolean) => {
    this.setState({ isTermsModalOpen: isOpen, action: {type: Actions.SUBSCRIPTION_BILLING, active: true} })
  }
  redirectToSignInPage = (name?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), name || 'EmailAccountLoginBlock');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleModal = (isOpen: boolean) => {
    this.setState({ isModalOpen: isOpen, action: {type: Actions.SUBSCRIPTION_BILLING, active: true} });
  };
  validatePassword(value:string) {
    const isValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(value) || value === '';
    const isConfirmValid = value !== '' && this.state.confirm_password === value;
    const isNotSameAsOld = value !== this.state.old_password;

    this.setState({
      new_password: value,
      inputErrors: { ...this.state.inputErrors, newPasswordError: !isValid || !isNotSameAsOld, confirmNewPasswordError: !isConfirmValid },
      errorMessages: {...this.state.errorMessages, newPasswordErrorMsg: !isNotSameAsOld
        ? "New password cannot be the same as the old password"
        : "Please enter a valid password that meets the criteria", confirmNewPasswordErrorMsg: "Passwords do not match"},
      btnContinueBlockStatus: !isValid || !isNotSameAsOld
    })
  }
  validateConfirmPassword(value:string) {
    const isValid = value !== '' && this.state.new_password === value;
    this.setState({
      confirm_password: value,
      inputErrors: { ...this.state.inputErrors, confirmNewPasswordError: !isValid },
      errorMessages: {...this.state.errorMessages, confirmNewPasswordErrorMsg: "Passwords do not match"},
      btnContinueBlockStatus: !isValid
    });
  }
  handleValues = (name: string, value: string) => {
    switch (name) {
      case 'old password':
        const isNotSameAsOld = value !== this.state.new_password;
        this.setState({
          old_password: value, 
          inputErrors: { ...this.state.inputErrors, oldPasswordError: false, newPasswordError: !isNotSameAsOld },
          errorMessages: {...this.state.errorMessages, newPasswordErrorMsg: "New password cannot be the same as the old password"},
          btnContinueBlockStatus: !isNotSameAsOld
        });
        break;
      case 'new password':
        this.validatePassword(value);
        break;
      case 'confirm password':
        this.validateConfirmPassword(value);
        break;
      default:
        break;
    }
  }
  handleInput = (e: any) => {
    let { name, value } = e.target;
    switch(name) {
      case 'old password':
        this.handleValues(name,value);
        break;
      case 'new password':
        this.handleValues(name,value);
        break;
      case 'confirm password':
        this.handleValues(name,value);
        break;
      default:
        break;
    }
  }
  onClickReset = () => {    
    this.setState({inputErrors: {
      newPasswordError: false,
      oldPasswordError: false,
      confirmNewPasswordError: false
    }, errorMessages: {
      newPasswordErrorMsg: "",
      oldPasswordErrorMsg: "",
      confirmNewPasswordErrorMsg: ""
    }, old_password: "", new_password: "", confirm_password: ""})
  }
  formatPhoneString = (value: string) => {
    if(value){
      return `+${value.slice(0,1)} ${value.slice(1,4)} ${value.slice(4,7)} ${value.slice(7,)}`
    }
  }
  onClickSubmit = () => {
    if(Actions.DELETE_ACCOUNT === this.state.action.type && this.state.modalData.stage === "first") {
      const authMethod = this.state.AuthMethod === AuthenticationMethod.EMAIL;
      const userData = authMethod ? this.state.email : this.state.formatedPhoneNumber;
      this.setState({isVerifyModalOpen: true, isModalOpen: false, verifyModalData: {
        title: "Account Deleting",
        description: `To delete your account please enter the code sent to your ${authMethod ? "email address" : "mobile number"}`,
        userData,
        btn: {
          title: "Delete Account"
        }
      }}, () => this.getDeleteAccountOtp())
    } else if(Actions.DELETE_ACCOUNT === this.state.action.type && this.state.modalData.stage === "last") {
      this.setState({isModalOpen: false });
      this.redirectToSignInPage()
    } else if(Actions.CHANGE_PASSWORD === this.state.action.type && this.state.modalData.stage === "first" && this.validateForm()) {
        this.changePasswordApiRequest()
    } else if(Actions.CHANGE_PASSWORD === this.state.action.type && this.state.modalData.stage === "last") {
      this.setState({isModalOpen: false, modalData: initialModalData, action: {type: Actions.SUBSCRIPTION_BILLING, active: true}});
    }
  };
  onClickContinue = () => {
    if(Actions.DELETE_ACCOUNT === this.state.action.type) {
      this.deleteAccount()
    }
  };
  validateForm = () => {
    let isValid = true;
    if(!this.state.old_password) {
      this.setState({btnContinueBlockStatus: true, inputErrors: {...this.state.inputErrors, oldPasswordError: true}, errorMessages: {...this.state.errorMessages, oldPasswordErrorMsg: "Please enter old password"}})
      isValid = false;
    }
    else if(!this.state.new_password) {
      this.setState({btnContinueBlockStatus: true,inputErrors: {...this.state.inputErrors, newPasswordError: true}, errorMessages: {...this.state.errorMessages, newPasswordErrorMsg: "Please enter new password"}})
      isValid = false;
    }
    else if(!this.state.confirm_password) {
      this.setState({btnContinueBlockStatus: true,inputErrors: {...this.state.inputErrors, confirmNewPasswordError: true}, errorMessages: {...this.state.errorMessages, confirmNewPasswordErrorMsg: "Please confirm new password"}})
      isValid = false;
    }
    return isValid
  }
  getTermsAndPolicy = () => {
    const authToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTermsPolicyCallID = requestMessage.messageId;

    if(this.state.action.type === Actions.TERMS_AND_CONDITIONS) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.termsEndPoint}`
      );
    } 
    else if(this.state.action.type === Actions.PRIVACY_POLICY) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.privacyPolicyEndPoint}`
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleGetTermsResponse = (id: string, message: Message) => {
    if (id === this.getTermsPolicyCallID) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.error) return
      if (responseJson.errors && responseJson.errors[0].token) {
        this.redirectToSignInPage()
      }
      if (responseJson.data) {
        this.setState({ termsData: { title: this.state.action.type === Actions.TERMS_AND_CONDITIONS ? configJSON.settings.termsTxt : configJSON.settings.privacyPolicyTxt, data: responseJson.data } })
      }
    }
  }

  getCustomizableProfile = () => {
    const authToken = localStorage.getItem("token");
    const header = {
      token: authToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleGetUserProfileResponse = (id: string, message:Message) => {
    if(id === this.getUserProfileCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error || responseJson?.errors && responseJson.errors[0]?.token) {
        this.redirectToSignInPage()
      }
      if(responseJson) {
        this.setState({
          email: responseJson.email,
          phone_number: `+${responseJson.phone_number}`,
          formatedPhoneNumber: this.formatPhoneString(responseJson.phone_number)
      });
    }
  }}
  handleOtp = (otpnumber: string) => {
    this.setState({ otp: otpnumber, otpError: false });
  };

  getAuthMethod = () => {
    const authToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAuthMethodCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.authMethodEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  updateAuthMethod = () => {
    const authToken = localStorage.getItem("token");
    const header = {
      "Content-Type": "multipart/form-data",
      token: authToken
    };

    const authMethod = this.state.AuthMethod === AuthenticationMethod.EMAIL ? "email":"sms";
    const formdata = `verification_method=${encodeURIComponent(authMethod)}`;

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateAuthMethodCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAuthMethodEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  handleGetAuthResponse = (id: string, message:Message) => {
    if(id === this.getAuthMethodCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error) return
      if(responseJson.errors && responseJson.errors[0].token) {
        this.redirectToSignInPage()
      }
      if(responseJson.verification_method) {
        const authMethod = responseJson.verification_method === "sms" ? AuthenticationMethod.PHONE : AuthenticationMethod.EMAIL;
        this.setState({AuthMethod: authMethod})
    }
  }}
  handleUpdateAuthResponse = (id: string, message:Message) => {
    if(id === this.updateAuthMethodCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error) return
      if(responseJson.errors && responseJson.errors[0].token) {
        this.redirectToSignInPage()
      }
      if(responseJson.message) {
        toast.success(responseJson?.message || "Verification method updated successfully");
    }
  }}
  changePasswordApiRequest = () => {
    const authToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    const httpBody = {
      current_password: this.state.old_password,
      new_password: this.state.new_password,
      confirm_new_password: this.state.confirm_password
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.changePasswordMethodCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.changePasswordEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getDeleteAccountOtp = () => {
      const authToken = localStorage.getItem("token");
      const header = {
        token: authToken
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.deleteotpCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteAccountEndPoint}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteAccount = () => {
    if(!this.state.otp) {
      this.setState({otpError: true})
    } else {
      const authToken = localStorage.getItem("token");
      const header = {
        "Content-Type": "multipart/form-data",
        token: authToken
      };

      const formdata = `otp=${encodeURIComponent(this.state.otp)}`;

      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.deleteAccountCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.verifyDeleteAccountEndPoint}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };
  handleDeleteAccountResponse = (id: string, message:Message) => {
    if(id === this.deleteAccountCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error || responseJson.errors?.length) {
        this.setState({otpError: true})
        if(responseJson.errors[0]?.toLowerCase() === "otp has expired") {
          this.setState(() => ({resendOtpSent: false, otpErrorMessages: {...this.state.otpErrorMessages, otpValidationError: responseJson.errors[0]}}))
        }
      } 
      else if(responseJson?.errors && responseJson.errors[0]?.token) {
        this.redirectToSignInPage()
      }
      else if(responseJson.message) {
      this.setState({isVerifyModalOpen: false, isModalOpen: true, otp: "", otpError: false, modalData: {
        stage: "last",
        header: {
          title: "Account deleted",
        },
        description: "Account successfully deleted. Thank you for using Advantage PHC",
        icon: true,
        btn1: {
          title: "",
          visible: false
        },
        btn2: {
          title: "Close",
          visible: true,
          color: "#fff",
          backgroundColor: "linear-gradient(99.09deg, #FCD34D 2.64%, #D97706 100%)"
        },
      }})}
  }}
  sendResendCode = () => {
    this.setState({otpError: false})
    const authToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    const authMethod = this.state.AuthMethod === AuthenticationMethod.EMAIL
    const httpBody = authMethod ? {
      data: {
        email: this.state.email
      }
    } : {
      data: {
        full_phone_number: this.state.phone_number
      }
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.resendCodeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.resendCodeEndPoint}/${authMethod ? "resend_email_otp" : "resend_mobile_otp"}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleChangePasswordResponse = (id: string, message:Message) => {
    if(id === this.changePasswordMethodCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error) {
        this.setState({inputErrors: {...this.state.inputErrors, oldPasswordError: true}, errorMessages: {...this.state.errorMessages, oldPasswordErrorMsg: responseJson.error}})
      }
      if(responseJson?.errors && responseJson.errors[0]?.token) {
        this.redirectToSignInPage()
      }
      if(responseJson.message) {
        this.onClickReset();
        this.setState({isVerifyModalOpen: false, isModalOpen: true, otp: "", otpError: false, modalData: {
          stage: "last",
          header: {
            title: "Password Changing",
          },
          description: "Your password has been changed successfully",
          icon: true,
          svg: successSvg.default,
          btn1: {
            title: "",
            visible: false
          },
          btn2: {
            title: "Continue",
            visible: true,
            color: "#fff",
            backgroundColor: "linear-gradient(99.09deg, #FCD34D 2.64%, #D97706 100%)"
          }
        }})
      }
  }}
  handleResendCodeResponse = (id: string, message:Message) => {
    if(id === this.resendCodeCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error) return
      if(responseJson?.errors && responseJson.errors[0]?.token) {
        this.redirectToSignInPage()
      }
      if(responseJson) {
        this.setState({
          resendOtpSent: true
      }, () => {
        setTimeout(()=>{
          this.setState({
            resendOtpSent: false
          })
        },3000)
      })
    }
  }}
  // Customizable Area End
}
