import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface Touched {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  organization: boolean;
  teamName: boolean;
  userType: boolean;
  rating: boolean;
  gender: boolean;
  address: boolean;
  country: boolean;
  state: boolean;
  city: boolean;
}  

type FileType = {
  name: string;
  file_type: string;
  size: string;
  url: string;
};

export interface AddCaseTouched {
  caseDescription: boolean;
  courtLocation: boolean;
  proceedingDate: boolean;
  caseDetails: boolean;
}
export interface AddCaseError {
  caseDescription: string;
  courtLocation: string;
  proceedingDate: string;
  caseDetails: string;
}
interface FormValues {
  caseDescription: string;
  courtLocation: string;
  proceedingDate: string;
  caseDetails: string;
  files: File[];
}



export interface Error {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    };
  };
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  viewCaseDetailsData:any ;
  navigateId : any ;
  casesList:any;
  caseListToShow :any ;
  totalItems:number ;
  itemsPerPage:number ;
  currentPage:number ;
  loading: boolean;
  userId: string;
  data: Data[];
  filterData: Data[];
  page: number;
  dataLength: number;
  rowsPerPage: number;
  openDialogName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: File | null | string;
  isChange: boolean;
  error: string | null;
  formValues: FormValues;
  isOpenConfrimationDialog: boolean,
  cashListing:{userId: string;
    caseSummary: string;
    caseSubmitted:string;
    documents: string;
    status: string}[]
    uploadProgress:any ;
    uploadingFile:any | null
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  getSingleCaseDetailApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      viewCaseDetailsData:{},
      navigateId:'',
      casesList:[],
      caseListToShow :[],
      totalItems :0,
      itemsPerPage:10,
      currentPage:1 ,
      loading: false,
      userId: "",
      data: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 1,
      filterData: [],
      openDialogName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: null,
      isChange: false,
      error: null,
      isOpenConfrimationDialog: false,
      formValues: {
        caseDescription: '',
        courtLocation: '',
        proceedingDate: '',
        caseDetails: '',
        files: [],
      },
      cashListing:configJSON.data,
      uploadProgress:{} ,
      uploadingFile: null
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start 
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
   
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
          this.setState({ loading: false });
        } else if (responseJson.errors) {
          toast.error(responseJson.errors);
          this.setState({ loading: false });
        } else if (apiRequestCallId == this.getUserListApiCallId) {
          this.setState({casesList:responseJson.data})
        }else if (apiRequestCallId == this.getSingleCaseDetailApiCallId) {
          this.setState({viewCaseDetailsData:responseJson.data})
          // this.setState({viewCaseDetailsData:responseJson.data})
        } else if (apiRequestCallId == this.addUserApiCallId) {
          toast.success("User added successfully!");
          this.setState({ openDialogName: "" });
          this.onCancel();
          this.getUserList();
        } else if (apiRequestCallId == this.editUserApiCallId) {
          toast.success("User edited successfully!");
          this.setState({ openDialogName: "", isChange: false });
          this.onCancel();
          this.getUserList();
        }
        this.cardGen();
      } 
      this.setState({ loading: false });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {  
   const detailId = localStorage.getItem('navigatedetailId') 
    this.getUserList() 
    this.getSingleCaseDetail(detailId)
  }
 
  cardGen = () => { 
    const { currentPage, itemsPerPage,  casesList } = this.state;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToDisplay = casesList.slice(startIndex, endIndex);
    this.setState({ caseListToShow: itemsToDisplay ,totalItems: casesList.length});
  };

  handlePageChange = (event :any, value:number) => { 
    this.setState({ currentPage: value }, () => {
      this.cardGen();
    });
  };

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  onAddBtnClicked = () => {
    this.setState({ openDialogName: "Add" });
  };

  onFileChange = (file: File | null) => {
    this.setState({ file });
  };

  onEditBtnClicked = (item: Data) => {
    this.setState({
      userId: item.userId,
      firstName: item.firstName,
      lastName: item.lastName,
      phoneNumber: item.phoneNumber,
      email: item.email,
      organization: item.organization,
      teamName: item.teamName,
      userType: item.userType,
      rating: item.rating,
      gender: item.gender,
      address: item.address,
      country: item.country,
      state: item.state,
      city: item.city,
      file: item.file,
      openDialogName: "Edit",
    });
  };

  onCancel = () => {
    this.setState({
      openDialogName: "",
      userId: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: "",
    });
  };

  getUserList = ()=>{  
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserListApiEndPoint
        );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ loading: false });
  };

  getSingleCaseDetail = (id:any)=>{   
    const token = localStorage.getItem("token")
    this.setState({ loading: true }); 
    const header = {
      "Content-Type": configJSON.getSingleCaseApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleCaseDetailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${ configJSON.getSingleCaseListApiEndPoint}/${id}`
        );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSingleCaseListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 
  onAddTask = () => {
    this.setState({ loading: true });

    const header = {
      token: localStorage.getItem("accessToken"),
    };

    const formData = new FormData();
    formData.append("form[first_name]", this.state.firstName);
    formData.append("form[last_name]", this.state.lastName);
    formData.append("form[phone_number]", this.state.phoneNumber);
    formData.append("form[organization]", this.state.organization);
    formData.append("form[team_name]", this.state.teamName);
    formData.append("form[i_am]", this.state.userType);
    formData.append("form[gender]", this.state.gender);
    formData.append("form[stars_rating]", this.state.rating);
    formData.append("form[email]", this.state.email);
    formData.append("form[address]", this.state.address);
    formData.append("form[country]", this.state.country);
    formData.append("form[state]", this.state.state);
    formData.append("form[city]", this.state.city);
    formData.append("form[file]", this.state.file as Blob);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addUserApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onEditTask = () => {
    this.setState({ loading: true });

    const header = {
      token: localStorage.getItem("accessToken"),
    };

    const formData = new FormData();
    formData.append("form[first_name]", this.state.firstName);
    formData.append("form[last_name]", this.state.lastName);
    formData.append("form[phone_number]", this.state.phoneNumber);
    formData.append("form[organization]", this.state.organization);
    formData.append("form[team_name]", this.state.teamName);
    formData.append("form[i_am]", this.state.userType);
    formData.append("form[gender]", this.state.gender);
    formData.append("form[stars_rating]", this.state.rating);
    formData.append("form[email]", this.state.email);
    formData.append("form[address]", this.state.address);
    formData.append("form[country]", this.state.country);
    formData.append("form[state]", this.state.state);
    formData.append("form[city]", this.state.city);
    if (this.state.isChange) {
      formData.append("form[file]", this.state.file as Blob);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editUserApiEndPoint}/${this.state.userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getStatusStyle = (status: any) => {
    const baseStyle = {
      borderRadius: '20px',
      padding: '5px 15px',
      display: 'inline-flex',
      fontWeight: '500',
      fontSize: '12px',
    };

    switch (status) {
      case 'New case':
        return {
          ...baseStyle,
          backgroundColor: '#D4F1C4', 
          color: '#008000'           
        };
      case 'Awaiting customer response':
      case 'Advocate':
      case 'Awaiting CSR':
      case 'Awaiting insurance response':
        return {
          ...baseStyle,
          backgroundColor: '#FFF3CD', 
          color: '#856404'            
        };
      case 'Closed':
        return {
          ...baseStyle,
          backgroundColor: '#F8D7DA', 
          color: '#721C24'           
        };
      default:
        return {
          ...baseStyle,
          backgroundColor: '#E2E3E5', 
          color: '#383D41'   
        };
    }
  }

  simulateUpload = (file:any) => {
    this.setState({ uploadingFile: file.name }); 

    const progressInterval :any = setInterval(() => {
      this.setState((prevState) => {
        const newProgress = { ...prevState.uploadProgress };

        if (newProgress[file.name] < 100) {
          newProgress[file.name] += 10;
        } else {
          clearInterval(progressInterval);
          this.setState({ uploadingFile: null }); 
        }
        return { uploadProgress: newProgress };
      });
    }, 500);
  };

  handleNavigateDetails=(id:string)=>{ 
    this.setState({ navigateId : id } ) 
    localStorage.setItem('navigatedetailId' ,id)
    this.props.navigation.navigate('ViewCaseDetails');
    this.getSingleCaseDetail(id)
    
  } 
 
  downloadFile = async (file: FileType) => { 
    try {
      const response = await fetch(file.url);
      const blob = await response.blob();
      
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = file.name;
      
      link.click();
      
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
    files: File[]
  ) => {
    const newFiles = Array.from(event.currentTarget.files || []);
    const maxFileSize = 30 * 1024 * 1024; 
    const allowedFileTypes = ['application/pdf', 'image/jpeg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    if (files.length + newFiles.length > 5) {
      this.setState({ error: 'You can attach up to 5 documents for your case.' });
    } else {
      const oversizedFiles = newFiles.filter((file) => file.size > maxFileSize);
      if (oversizedFiles.length > 0) {
        this.setState({ error: 'Maximum file size is 30 Mb.' });
      } else {
        const invalidFileTypes = newFiles.filter((file) => !allowedFileTypes.includes(file.type));
        if (invalidFileTypes.length > 0) {
          this.setState({ error: 'Invalid file type. Only pdf, jpg, word files are allowed.' });
        } else {
          this.setState({ error: null });
          setFieldValue('files', [...files, ...newFiles]);

          const uploadProgress = { ...this.state.uploadProgress };
          newFiles.forEach(file => {
            uploadProgress[file.name] = 0; 
            this.simulateUpload(file);  
          });
          this.setState({ uploadProgress });
        }
      }
    }
  };

  handleRemoveFile = (
    index: number,
    setFieldValue: (field: string, value: any) => void,
    files: File[]
  ) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFieldValue('files', newFiles);
  };
  // Customizable Area End
}
