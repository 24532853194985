import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import { CustomModal } from './SuccessDialog.web';

interface Props {
    open: boolean;
    handleClose: any;
}

export default function ErrorDialog({ open, handleClose }: Props) {
    return (
        <CustomModal open={open}>
            <DialogContent>
                <Stack height={"100%"} alignItems={"center"} justifyContent={"center"} marginTop={"1rem"} gap={"20px"}>
                    <svg width="42" height="37" viewBox="0 0 42 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.5983 2.29688L40.8663 32.2049C41.1297 32.6609 41.2683 33.1782 41.2683 33.7049C41.2683 34.2315 41.1297 34.7488 40.8664 35.2048C40.6031 35.6609 40.2244 36.0396 39.7683 36.3029C39.3123 36.5662 38.795 36.7049 38.2683 36.7049H3.73235C3.20574 36.7049 2.68842 36.5662 2.23237 36.3029C1.77633 36.0396 1.39762 35.6609 1.13433 35.2048C0.87103 34.7488 0.732419 34.2315 0.732422 33.7049C0.732425 33.1782 0.871044 32.6609 1.13435 32.2049L18.4023 2.29688C19.5563 0.296875 22.4423 0.296875 23.5983 2.29688ZM21.0003 5.79687L5.46435 32.7049H36.5363L21.0003 5.79687ZM21.0003 26.0009C21.5308 26.0009 22.0395 26.2116 22.4146 26.5867C22.7896 26.9617 23.0003 27.4704 23.0003 28.0009C23.0003 28.5313 22.7896 29.04 22.4146 29.4151C22.0395 29.7902 21.5308 30.0009 21.0003 30.0009C20.4699 30.0009 19.9612 29.7902 19.5861 29.4151C19.2111 29.04 19.0003 28.5313 19.0003 28.0009C19.0003 27.4704 19.2111 26.9617 19.5861 26.5867C19.9612 26.2116 20.4699 26.0009 21.0003 26.0009ZM21.0003 12.0009C21.5308 12.0009 22.0395 12.2116 22.4146 12.5867C22.7896 12.9617 23.0003 13.4704 23.0003 14.0009V22.0009C23.0003 22.5313 22.7896 23.04 22.4146 23.4151C22.0395 23.7902 21.5308 24.0009 21.0003 24.0009C20.4699 24.0009 19.9612 23.7902 19.5861 23.4151C19.2111 23.04 19.0003 22.5313 19.0003 22.0009V14.0009C19.0003 13.4704 19.2111 12.9617 19.5861 12.5867C19.9612 12.2116 20.4699 12.0009 21.0003 12.0009Z" fill="#DC2626" />
                    </svg>
                    <Stack alignItems={"center"} justifyContent={"center"} gap={"64px"}>
                        <Stack justifyContent={"center"} alignItems={"center"} gap={"8px"}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#0F172A" }}>
                                {"Verification Failed"}
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ textAlign: "center", color: "#64748B", width: "443px" }}>
                                {"Ooops... something went wrong. Please recheck the information and try again "}
                            </Typography>
                        </Stack>
                        <Button className='btn edit-btn' data-testid="btn1" onClick={() => handleClose("update-payment", true)}>{"Try again"}</Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </CustomModal>
    );
}