import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { headLogo, sidePic } from "./assets"
import moment from "moment";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

const ErrorMessages = {
  firstNameError: "Please enter a valid first name",
  lastNameError: "Please enter a valid last name",
  birthDateError: "Please enter a valid birth date",
  phoneNumError: "Please enter a valid US phone number",
  emailError: "Please enter a valid email",
  relationShipError: "Please enter a valid relationship",
}

export const OTPMessages = {
  otpValidationError: "Incorrect code, please try again",
  codeSentSuccess: "The code was sent successfully",
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

export interface User {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  participantId: string;
  birthDate: string;
}
export interface InputErrors {
  firstNameError: boolean,
  lastNameError: boolean,
  emailError: boolean,
  phoneError: boolean,
  birthDateError: boolean,
  relationShipError: boolean,
}
const initialUserState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  birthDate: "",
  participantId: "",
}
export const initialUpdateUserState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  birthDate: ""
}
export const initialInputErrorsState = {
  firstNameError: false,
  lastNameError: false,
  emailError: false,
  phoneError: false,
  birthDateError: false,
  relationShipError: false,
}
export const initialDependanrErrorsState = {
  firstNameError: false,
  lastNameError: false,
  birthDateError: false,
  relationShipError: false,
}
const {userProfileObj} = require("./config")

export enum Relationship {
  SELECT_OPTION="select option",
  SPOUSE="spouse",
  CHILD="child"
}

export enum Action {
  GET_PROFILE,
  EDIT_PROFILE,
  CREATE_DEPENDANT,
  EDIT_DEPENDANT,
  DELETE_DEPENDANT,
  SAVE_DEPENDANT,
  SAVE_PROFILE
};
export enum VerificationEnum {
  PHONE_VERIFICATION,
  EMAIL_VERIFICATION,
  BOTH,
  NONE
}
export type EditUserInputName = "firstName" | "lastName" | "phoneNumber" | "email" | "birthDate"; 
export type EditDependantInputName = "firstName" | "lastName" | "birthDate" | "relationship";


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;

  token: string;
  avatarName: string;
  avatarAbbr: string;
  isDisabled:boolean;
  mobileOpen:boolean;
  isClosing:boolean;
  headerBtnTxt:string;
  userDetails: User;
  updateUserData: Omit<User, "participantId">;
  dependantData: Omit<User, "participantId" | "email" | "phoneNumber"> & {relationship: Relationship | null};
  inputErrors: InputErrors;
  inputDependantErrors: Omit<InputErrors, "emailError" | "phoneError"> & {relationShipError: boolean};

  errorMessages: Record<string, string>;
  otpErrorMessages: Record<string, string>;
  resendOtpSent: boolean;
  profileAction: Action;
  dependantsArray: {id: string, title: string,firstName: string, lastName: string, birthDate: string, relationship: Relationship}[];

  verifySuccessObj: {open: boolean, msg: string};
  
  isVerificationOpen: boolean;
  verificationType: VerificationEnum;
  dependantId: string;

  otp: string;
  emailOtp:string;
  otpAuthToken: string;
  head: string;
  sidePic: string;
  otpError: boolean;
  otpEmailError: boolean;
  phoneNumber: string;
  email:string;
  isDeleteDepandantOpen: boolean;
  isDepandantAlertOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserProfileCallId: string = "";
  updateUserProfileCallId: string = "";
  emailOtpVerifyApiCallId: string = "";
  otpVerifyApiCallId: string = "";
  listDependentCallId: string = "";
  createDependentCallId: string = "";
  updateDependentCallId: string = "";
  deleteDependentCallId: string = "";
  resendCodeCallId: string = "";

  verificationType = {
    phone: false, 
    email: false
  };

  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],

      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      dependantsArray: [],

      otpErrorMessages: OTPMessages,

      token: "",
      mobileOpen:false,
      isClosing:false,
      headerBtnTxt: userProfileObj.editBtnTxt,
      avatarName: "",
      avatarAbbr: "",
      userDetails: initialUserState,
      inputErrors: initialInputErrorsState,
      inputDependantErrors: initialDependanrErrorsState,
      updateUserData: initialUpdateUserState,
      isVerificationOpen: false,
      isDisabled: false,
      verificationType: VerificationEnum.NONE,

      profileAction: Action.GET_PROFILE,
      errorMessages: ErrorMessages,
      resendOtpSent: false,
      dependantId: "",
      verifySuccessObj: {
        open: false,
        msg: ""
      },

      otp: "",
      emailOtp:"",
      otpAuthToken: "",
      head: headLogo.default,
      sidePic: sidePic.default,
      otpError: false,
      otpEmailError: false,
      isDeleteDepandantOpen: false,
      isDepandantAlertOpen: false,
      dependantData: {firstName: "", lastName: "", birthDate: "", relationship: null}

      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getCustomizableProfile()
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    this.handleGetUserProfileResponse(apiRequestCallId, message)
    this.handleSaveUserProfileResponse(apiRequestCallId, message)
    this.handlePhoneOtpResponse(apiRequestCallId, message)
    this.handleListDependentResponse(apiRequestCallId, message)
    this.handleCreateDependentResponse(apiRequestCallId, message)
    this.handleUpdateDependentResponse(apiRequestCallId, message)
    this.handleDeleteDependentResponse(apiRequestCallId, message)
    this.handleResendCodeResponse(apiRequestCallId, message)
    // Customizable Area End
  }

  // Customizable Area Start

  refactorTitleOfModal = () => {
    if(this.state.verificationType === VerificationEnum.PHONE_VERIFICATION){
      return userProfileObj.phoneVerifyTxt
    }
    else if(this.state.verificationType === VerificationEnum.EMAIL_VERIFICATION) {
      return userProfileObj.emailVerifyTxt
    }
    else return userProfileObj.emailPhoneVerifyTxt
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if(this.state.userDetails.id !== prevState.userDetails.id) {
      this.getDependentList()
    }
  }
  getFullUserName = (firstName: string, lastName: string) => {
    return `${firstName} ${lastName}`
  }

  handleDeleteDependantClose = () => {
    this.setState({isDeleteDepandantOpen: false})
  }
  
  handleDeleteDependantOpen = (id: string) => {
    this.setState({isDeleteDepandantOpen: true, dependantId: id})
  }
  handleAlertDependantClose = () => {
    this.setState({isDepandantAlertOpen: false})
  }

  otpVerify = (name:string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), name);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleOtp = (verificationType: VerificationEnum, otpnumber: string) => {
    if(verificationType === VerificationEnum.EMAIL_VERIFICATION || verificationType === VerificationEnum.BOTH) { 
      this.setState({ emailOtp: otpnumber  });
    } else {
      this.setState({ otp: otpnumber });
    }
    this.setState({ otpError: false });
  };

  onEditClick = () => {
    this.setState({profileAction: Action.EDIT_PROFILE, headerBtnTxt: userProfileObj.saveChangesBtnTxt})
  }
  onCancelClick = () => {
    this.setState({
      profileAction: Action.GET_PROFILE,
      headerBtnTxt: userProfileObj.editBtnTxt, 
      avatarName: this.getFullUserName(this.state.userDetails.firstName, this.state.userDetails.lastName) || userProfileObj.userNameTxt, 
      updateUserData: this.state.userDetails,
      inputErrors: initialInputErrorsState,
      dependantData: {firstName: "", lastName: "", birthDate: "", relationship: null},
      inputDependantErrors: initialDependanrErrorsState,
      isDisabled: false
    }, ()=> this.getAbbrOfUserName(this.state.userDetails.firstName, this.state.userDetails.lastName))
  }
  onAddNewDepandantClick = () => {
    if(this.state.dependantsArray.length >= 7) {
      this.setState({isDepandantAlertOpen: true})
      return false
    }
    this.setState({profileAction: Action.CREATE_DEPENDANT, avatarName: userProfileObj.newDependantTxt}, ()=> this.getAbbrOfUserName())
  }
  onEditDepandantClick = (id: string) => {
    this.setState({
      dependantId: id,
      profileAction: Action.EDIT_DEPENDANT,
      headerBtnTxt: userProfileObj.saveChangesBtnTxt
    }, () => {
      let dependantData: any = {};
      if(this.state.profileAction === Action.EDIT_DEPENDANT) {
        dependantData = this.state.dependantsArray.find(item => item.id == id)
      }
      this.setState({  
        dependantData: dependantData,
        avatarName: this.getFullUserName(dependantData.firstName, dependantData.lastName)
      }, () => this.getAbbrOfUserName(this.state.dependantData.firstName, this.state.dependantData.lastName))
    })
  }
  getAbbrOfUserName = (headerTxt1?: string, headerTxt2?: string) => {
    let avatarAbbr: string = "";
    if(headerTxt1 && headerTxt2) {
      avatarAbbr = `${headerTxt1.split(" ")[0].charAt(0)}${headerTxt2.split(" ")[0].charAt(0)}`;
    } else {
      avatarAbbr = this.state.avatarName.split(" ").map(item => item.charAt(0)).join("");
    }
    this.setState({avatarAbbr})
  }
  handleInputChange = (name: EditUserInputName, value: any) => {
    let flag: boolean = false;
    let updatedUserData = {...this.state.updateUserData};
    let inputErrors = {...this.state.inputErrors};
  
    switch(name){
      case "firstName":
        updatedUserData[name] = value;
        inputErrors.firstNameError = false;
        break;
        
      case "lastName":
        updatedUserData[name] = value;
        inputErrors.lastNameError = false;
        break;
        
      case "birthDate":
        if (value) {
          updatedUserData[name] = moment(value.$d).format("MM-DD-YYYY");
          inputErrors.birthDateError = false;
        }
        break;
  
      case "phoneNumber":
        if (value.length > 15) return;
        flag = this.state.userDetails.phoneNumber.replace(/\s+/g, "") !== value.replace(/\s+/g, "");
        this.verificationType.phone = flag;
        updatedUserData[name] = value;
        inputErrors.phoneError = false;
        break;
  
      case "email":
        flag = this.state.userDetails.email !== value;
        this.verificationType.email = flag;
        updatedUserData[name] = value;
        inputErrors.phoneError = false;
        break;
  
      default:
        return;
    }
    const { phone, email } = this.verificationType;
    let verificationType = VerificationEnum.NONE;
    if (phone && !email) {
      verificationType = VerificationEnum.PHONE_VERIFICATION;
    } else if (!phone && email) {
      verificationType = VerificationEnum.EMAIL_VERIFICATION;
    } else if (phone && email) {
      verificationType = VerificationEnum.BOTH;
    }
  
    this.setState({
      updateUserData: updatedUserData,
      inputErrors,
      verificationType,
      isDisabled: this.checkIsDisabled(updatedUserData),
    });
  };  
  handleDependantInputChange = (name: EditDependantInputName, value: any) => {
    switch(name){
      case "firstName":
        this.setState((prev) => ({dependantData: {...prev.dependantData, [name]: value}, inputDependantErrors: {...prev.inputDependantErrors, firstNameError: false}}));
        break;
      case "lastName":
        this.setState((prev) => ({dependantData: {...prev.dependantData, [name]: value}, inputDependantErrors: {...prev.inputDependantErrors, lastNameError: false}}));
        break;
      case "birthDate":
        if(value){
          const date = moment(value.$d).format("MM-DD-YYYY");
          this.setState((prev) => ({dependantData: {...prev.dependantData, [name]: date}, inputDependantErrors: {...prev.inputDependantErrors, birthDateError: false}}));
        }
        break;
      case "relationship":
          this.setState((prev) => ({dependantData: {...prev.dependantData, [name]: value}, inputDependantErrors: {...prev.inputDependantErrors, relationShipError: false}}));
        break;
      default: 
        break;
    }
    this.setState({isDisabled: this.checkIsDisabled(this.state.dependantData as Record<string, string>)})
  }

  checkIsDisabled = (obj: any) => {
    return Object.keys(obj).every(key => {
      const value = obj[key];
      if (value || value === null || value === undefined || value === '') {
        return false;
      }
      return true;
    })
  }

  calculateAge = (birthDate: string) => {
    const today = moment();
    const age = today.diff(birthDate, 'years');
    if(age >= 18) {
      return false
    } else {
      return true
    }
  }
  checkIsFutureDate = (inputDate: string) => {
    const today = moment();
    const enteredDate = moment(inputDate);
  
    return enteredDate.isAfter(today, 'day');
  }

  onSaveNewDependant = () => {
    if(this.validateDependantForm()) {
      this.createDependent();
      this.setState({
        profileAction: Action.GET_PROFILE, 
        avatarName: this.getFullUserName(this.state.userDetails.firstName,this.state.userDetails.lastName), 
        headerBtnTxt: userProfileObj.editBtnTxt, dependantData: {firstName: "", lastName: "", birthDate: "", relationship: null}}, () => {
          this.getAbbrOfUserName(this.state.userDetails.firstName, this.state.userDetails.lastName)
        });
    }
  }
  onDeleteDependant = () => {
    const dependantsArray = this.state.dependantsArray.filter(item => item.id !== this.state.dependantId);
    this.setState({dependantsArray,isDeleteDepandantOpen: false}, () => this.deleteDependent())
  }

  handleVerificationClose = () => {
    this.setState({isVerificationOpen: false})
  }
  handlePhoneVerifyButton = () => {
    this.otpVerify("OTPVerifyAuth");
    sessionStorage.setItem("user", JSON.stringify({email: this.state.updateUserData.email, phoneNumber: (this.state.updateUserData.phoneNumber).split(" ").join("").slice(1,)}))
  }
  checkIsVerify = () => {
    return Number(sessionStorage.getItem("verificationType") || VerificationEnum.NONE);
  }
  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
  };
  anyErrors = (errorObj: any) => {
    for (let arr of Object.entries(errorObj)) {
      let val = arr[1]
      if (val === true) {
        return true;
      }
    }
  }
  navTo = () => {
    this.props.navigation.goBack();
    this.setState({updateUserData: this.state.userDetails, verificationType: VerificationEnum.NONE})
  }
  validateForm = () => {
    let errorObj: InputErrors = {
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      phoneError: false,
      birthDateError: false,
      relationShipError: false
    };
    let messages = this.state.errorMessages;
    if (!/^[A-Za-z][a-zA-Z\s'-]*$/.test(this.state.updateUserData.firstName) || this.state.updateUserData.firstName == "" || this.state.updateUserData.firstName.length > 64) {
      errorObj.firstNameError = true;
    }
    if (!/^[A-Za-z][a-zA-Z\s'-]*$/.test(this.state.updateUserData.lastName) || this.state.updateUserData.lastName == "" || this.state.updateUserData.lastName.length>64) {
      errorObj.lastNameError = true;
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.state.updateUserData.email) || this.state.updateUserData.email === "" || this.state.updateUserData.email.length>64) {
      errorObj.emailError = true;
    }
    if (!/^(\+1\s?)?(?!000)(\(?[1-9]\d{2}\)?[\s.-]?)?[1-9]\d{2}[\s.-]?\d{4}$/.test(this.state.updateUserData.phoneNumber) || this.state.updateUserData.phoneNumber === "") {
      errorObj.phoneError = true;
    }    
    if (!moment(this.state.updateUserData.birthDate).isValid() || this.checkIsFutureDate(this.state.updateUserData.birthDate) || moment(this.state.updateUserData.birthDate).year() < 1900) {
      messages.birthDateError = "Please enter a valid birth date";
      errorObj.birthDateError = true;
    } else if (this.calculateAge(this.state.updateUserData.birthDate) ) {
      messages.birthDateError = "Birthdate must be at least 18 years old";
      errorObj.birthDateError = true;
    }
    this.setState({ inputErrors: { ...errorObj }, isDisabled: true, errorMessages: messages })
    const anyErrors = this.anyErrors(errorObj);
    if (anyErrors) {
      return false;
    }
    this.setState({ inputErrors: { ...errorObj }, isDisabled: false, errorMessages: ErrorMessages })
    return true
  }
  validateDependantForm = () => {
    let errorObj = {
      firstNameError: false,
      lastNameError: false,
      birthDateError: false,
      relationShipError: false
    };
    let messages = this.state.errorMessages;
    if (!/^[A-Za-z][a-zA-Z\s'-]*$/.test(this.state.dependantData.firstName) || this.state.dependantData.firstName == "" || this.state.dependantData.firstName.length>64) {
      errorObj.firstNameError = true;
    }
    if (!/^[A-Za-z][a-zA-Z\s'-]*$/.test(this.state.dependantData.lastName) || this.state.dependantData.lastName == "" || this.state.dependantData.lastName.length>64) {
      errorObj.lastNameError = true;
    }
    if (!moment(this.state.dependantData.birthDate).isValid() || this.checkIsFutureDate(this.state.dependantData.birthDate) || moment(this.state.dependantData.birthDate).year() < 1900) {
      messages.birthDateError = "Please enter a valid birth date";
      errorObj.birthDateError = true;
    }
    if(!this.state.dependantData.relationship || this.state.dependantData.relationship === Relationship.SELECT_OPTION) {
      errorObj.relationShipError = true;
    }
    this.setState({ inputDependantErrors: { ...errorObj }, isDisabled: true, errorMessages: messages })
    const anyErrors = this.anyErrors(errorObj);
    if (anyErrors) {
      return false;
    }
    this.setState({ inputDependantErrors: { ...errorObj }, isDisabled: false, errorMessages: ErrorMessages })
    return true
  }
  getCustomizableProfile = () => {
    const authToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: authToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getDependentList = () => {
    if(this.state.userDetails.id){
      const authToken = localStorage.getItem("token");
      const header = {
        "Content-Type": "multipart/form-data",
        token: authToken
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.listDependentCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.listDependentEndPoint}?dependent[profile_id]=${this.state.userDetails.id}`
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    }
  };
  createDependent = () => {
    if(this.state.userDetails.id){
      const authToken = localStorage.getItem("token");
      const header = {
        "Content-Type": configJSON.searchApiContentType,
        token: authToken
      };
      const formatBirthDate = moment(this.state.dependantData.birthDate).format("DD-MM-YYYY");
      const httpBody = {
        dependent:  {
          profile_id: this.state.userDetails.id,
          first_name: this.state.dependantData.firstName,
          last_name: this.state.dependantData.lastName,
          birthdate: formatBirthDate,
          relationship: this.state.dependantData.relationship
        }
      }    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      if(this.state.profileAction === Action.CREATE_DEPENDANT) {
        this.createDependentCallId = requestMessage.messageId;
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.listDependentEndPoint}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpPostMethod
        );
      } else {
        this.updateDependentCallId = requestMessage.messageId;
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.listDependentEndPoint}/${this.state.dependantId}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpPutMethod
        );
      }
       
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    }
  };
  deleteDependent = () => {
    if(this.state.userDetails.id){
      const authToken = localStorage.getItem("token");
      const header = {
        "Content-Type": configJSON.searchApiContentType,
        token: authToken
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteDependentCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.listDependentEndPoint}/${this.state.dependantId}?dependent[profile_id]=${this.state.userDetails.id}`
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpDeleteMethod
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    }
  };
  handleListDependentResponse = (id: string, message:Message) => {
    if(id === this.listDependentCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson?.error) return
      if(responseJson?.errors && responseJson.errors[0]?.token) {
        this.redirectToSignInPage()
      }
      if(responseJson) {
        this.setState({dependantsArray: responseJson.map((item: { id: string; title: string, first_name: string; last_name: string; birthdate: string; relationship: Relationship; }, indx:number) => ({
          id: item.id,
          title: `Dependant ${indx+1}`,
          firstName: item.first_name,
          lastName: item.last_name,
          birthDate: this.formatBirthDateString(item.birthdate),
          relationship: item.relationship
        }))})
      }
    }
  }
  handleCreateDependentResponse = (id: string, message:Message) => {
    if(id === this.createDependentCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson?.error) return
      if(responseJson?.errors && responseJson.errors[0]?.token) {
        this.redirectToSignInPage()
      }
      if(responseJson) {
        this.setState({dependantsArray: [...this.state.dependantsArray, {
          id: responseJson.id,
          title: `Dependant ${this.state.dependantsArray.length+1}`,
          firstName: responseJson.first_name,
          lastName: responseJson.last_name,
          birthDate: responseJson.birthdate,
          relationship: responseJson.relationship || Relationship.SELECT_OPTION
        }]})
      }
    }
  }
  handleUpdateDependentResponse = (id: string, message:Message) => {
    if(id === this.updateDependentCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson?.error) return
      if(responseJson?.errors && responseJson.errors[0]?.token) {
        this.redirectToSignInPage()
      }
      if(responseJson) {
        this.getDependentList();
      }
    }
  }
  handleDeleteDependentResponse = (id: string, message:Message) => {
    if(id === this.deleteDependentCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson?.error) return
      if(responseJson?.errors && responseJson.errors[0]?.token) {
        this.redirectToSignInPage()
      }
      if(responseJson?.message) {
        toast.success(responseJson?.message || "Dependent deleted successfully")
      }
    }
  }
  saveUserProfile = () => {
    if(this.validateForm()){
      const authToken = localStorage.getItem("token");
      const header = {
        "Content-Type": configJSON.searchApiContentType,
        token: authToken
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.updateUserProfileCallId = requestMessage.messageId;
      const {phoneNumber, firstName, lastName, birthDate,email} = this.state.updateUserData;
      const formatBirthDate = moment(birthDate).format("DD-MM-YYYY");
      const httpBody = {
        profile: {email, phone_number: `${phoneNumber.slice(1,).split(" ").join("")}`, last_name: lastName, first_name: firstName, birthdate: formatBirthDate},
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.updateCustomizableProfileEndPoint}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  redirectToSignInPage = () => {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock');
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  }
  formatPhoneeString = (value: string) => {
    if(value){
      return `+${value.slice(0,1)} ${value.slice(1,4)} ${value.slice(4,7)} ${value.slice(7,)}`
    }
  }
  formatBirthDateString = (birthDate: string) => {
    if(birthDate) {
      const date = new Date(birthDate);
      return moment(date).format("MM-DD-YYYY").split("-").join(".")
    } return "MM-DD-YYYY"
  }
  handleGetUserProfileResponse = (id: string, message:Message) => {
    if(id === this.getUserProfileCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error) return
      if(responseJson.errors && responseJson.errors[0]?.token) {
        this.redirectToSignInPage()
      }
      if(responseJson) {
        const avatarName = this.getFullUserName(responseJson.first_name,responseJson.last_name)
        this.setState({userDetails: {
          id: responseJson.id,
          firstName: responseJson.first_name,
          lastName: responseJson.last_name,
          email: responseJson.email,
          phoneNumber: `+${responseJson.phone_number}`,
          birthDate: this.formatBirthDateString(responseJson.birthdate),
          participantId: responseJson.participant_id
        },
        updateUserData: {
          id: responseJson.id,
          firstName: responseJson.first_name,
          lastName: responseJson.last_name,
          email: responseJson.email,
          phoneNumber: `+${responseJson.phone_number}`,
          birthDate: responseJson.birthdate
        }, avatarName}, () => this.getAbbrOfUserName(responseJson.first_name, responseJson.last_name))
      }
    }
  }
  handleSaveUserProfileResponse = (id: string, message:Message) => {
    if(id === this.updateUserProfileCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error) return
      if(responseJson) {
        const {firstName, lastName, birthDate} = this.state.updateUserData;
        this.setState((prev) =>({headerBtnTxt: userProfileObj.editBtnTxt, userDetails: {
          ...prev.userDetails,
          firstName: responseJson.first_name || firstName,
          lastName: responseJson.last_name || lastName,
          birthDate: this.formatBirthDateString(responseJson.birthdate || birthDate),
        }, avatarName: this.getFullUserName(responseJson.first_name || firstName, responseJson.last_name || lastName)}), () => this.getAbbrOfUserName(responseJson.first_name, responseJson.last_name));
        if(this.state.verificationType !== VerificationEnum.NONE){
          this.setState({isVerificationOpen: true, profileAction: Action.EDIT_PROFILE})
          sessionStorage.setItem("verificationType", JSON.stringify(this.state.verificationType))
        } else {
          this.setState({profileAction: Action.GET_PROFILE})
          toast.success("You have successfully updated your details")
          window.location.reload()
        }
      }
    }
  }
  handleOtpSubmit = (verificationType: VerificationEnum) => {
    if(verificationType === VerificationEnum.PHONE_VERIFICATION){
      this.handlePhoneOtpSubmit()
    } else if(verificationType === VerificationEnum.EMAIL_VERIFICATION || verificationType === VerificationEnum.BOTH) {
      this.handleEmailOtpSubmit()
    }
  }
  handlePhoneOtpSubmit = async() => {
    if (this.state.otp === '') {
      this.setState({ otpError: true });
    } else {
      const authToken = localStorage.getItem("token");
      const header = {
        "Content-Type": configJSON.searchApiContentType,
        "token" : authToken
      };
      const httpBody = {
        profile: {
          phone_number: this.state.phoneNumber,
          mobile_otp: this.state.otp
        }
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.otpVerifyApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.verifyPhoneOtpEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  handleResendCode = async() => {
    this.setState({otpError: false, resendOtpSent: false});
      const authToken = localStorage.getItem("token");
      const header = {
        "Content-Type": configJSON.searchApiContentType,
        "token" : authToken
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      if(this.checkIsVerify() === VerificationEnum.PHONE_VERIFICATION) {
        const httpBody = {
          profile: {
            phone_number: this.state.phoneNumber
          }
        };
        this.resendCodeCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.resenCodeEndPoint}/resend_mobile_otp`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
      } else {
        const httpBody = {
          profile: {
            email: this.state.email
          }
        };
        this.resendCodeCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.resenCodeEndPoint}/resend_email_otp`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
      }
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleResendCodeResponse = async(id: string, message:Message) => {
    if(id === this.resendCodeCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error) return
      if(responseJson.errors?.length) {
        this.setState(() => ({otpError: true}))
      }
      else if(responseJson.message) {
        this.setState({resendOtpSent: true});
        setTimeout(()=>{
          this.setState({
            resendOtpSent: false
          })
        },3000)
      }
    }
  }
  handleEmailOtpSubmit = async() => {
    if (this.state.emailOtp === '') {
      this.setState({ otpError: true });
    } else {
      const authToken = localStorage.getItem("token");
      const header = {
        "Content-Type": configJSON.searchApiContentType,
        "token" : authToken
      };
      const httpBody = {
        profile: {
          email: this.state.email,
          email_otp: this.state.emailOtp
        }
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.otpVerifyApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.verifyEmailOtpEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  handlePhoneOtpResponse = async (id: string, message:Message) => {
    if(id !== this.otpVerifyApiCallId) return
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error) return

      if(responseJson.errors?.length) {
        this.setState({ otpError: true });

        const otpErrorMessage = responseJson.errors[0]?.toLowerCase();
        if (otpErrorMessage === "otp has expired") {
          this.setState({
            resendOtpSent: false,
            otpErrorMessages: {
              ...this.state.otpErrorMessages,
              otpValidationError: "Code has expired, please request a new one",
            },
          });
        }
        return;
      }

      if(responseJson.profile) {
        if(this.checkIsVerify() === VerificationEnum.BOTH) {
          sessionStorage.setItem("verificationType", JSON.stringify(VerificationEnum.PHONE_VERIFICATION))
          this.setState({verificationType: VerificationEnum.BOTH, otpError: false, resendOtpSent: false})
        }
        else {
          this.setState({verifySuccessObj: {open: true, msg: this.checkIsVerify() === VerificationEnum.PHONE_VERIFICATION ? userProfileObj.phoneVerifySuccessTxt : userProfileObj.emailVerifySuccessTxt}});
        }
      }
  }
  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseURLGetProfileAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  };

  // Customizable Area End
}
