import React from "react";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

import ContactusController, { Props } from "./ContactusController";

// Customizable Area Start
import Layout from "../../landingpage/src/Layout.web";
import { ToastContainer } from "react-toastify";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material";
import {Textarea} from '@mui/joy';
import { CssBaseline } from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import SuccessModal from './components/Modal.web';

const configJSON = require("./config");
import {headPic} from "./assets"
import MuiSelect from "./components/MuiSelect.web";
import {CONTACT_METHOD, errorMessages} from "./ContactusController"


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            {/* Customizable Area Start */}
            <Layout id="" navigation={this.props.navigation}>
              <ToastContainer />
                <Box component="main" sx={{ flexGrow: 1, width: "100%"}}>
                  <Typography sx={{
                    px: 1, py: 2,
                    color: "#1E293B",
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontWeight: 700,
                    lineHeight: "32px",
                    letterSpacing: 0
                  }} className="page-title">{configJSON.labelTitleText}</Typography>
                  <Grid container style={styles.FontFam}>
                    <Grid item xs={12} md={6}>
                       <Grid container>
                           <Grid item xs={12}>
                               <Grid container sx={styles.container}>
                                   <Stack gap={"40px"}>
                                      <Stack gap={"12px"}>
                                          <Typography sx={{color: "#0F172A", fontSize: "30px", fontWeight: 700, letterSpacing: "-0.5%", fontFamily: "Inter"}}>{configJSON.labelBodyText}</Typography>
                                          <Typography sx={{color: "#475569", fontSize: "16px", fontWeight: 400, fontFamily: "Inter"}}>{configJSON.paraBodyText}</Typography>
                                      </Stack>
                                      <Stack gap={"32px"}>
                                          <MuiSelect 
                                            label={configJSON.select1Title} 
                                            value={this.state.preferred_method_of_contact} 
                                            name={"preferred_method_of_contact"}
                                            error={this.state.inputErrors.preferredMethodOfContactError} 
                                            errorMessage={errorMessages.selectErrorMsg}
                                            handleInput={this.handleInput}
                                            menuItem={[
                                              {label: CONTACT_METHOD["SELECT_OPTION"], value: CONTACT_METHOD.SELECT_OPTION},
                                              {label: CONTACT_METHOD["EMAIL"], value: CONTACT_METHOD.EMAIL},
                                              {label: "Phone number", value: CONTACT_METHOD.PHONE}
                                            ]}
                                          />
                                          <MuiSelect
                                            label={configJSON.select2Title} 
                                            value={this.state.submitting_for}
                                            name={"submitting_for"} 
                                            error={this.state.inputErrors.submittingForError} 
                                            errorMessage={errorMessages.selectErrorMsg}
                                            handleInput={this.handleInput} 
                                            menuItem={[
                                              {label: CONTACT_METHOD["SELECT_OPTION"], value: CONTACT_METHOD.SELECT_OPTION},
                                              ...this.state.dependantsArray?.map(item => ({label: item.fullName, value: item.fullName}))
                                            ]}  
                                          />
                                          <Stack gap={"4px"}>
                                            <CssVarsProvider>
                                              <CssBaseline />
                                                  <Typography sx={{
                                                      color: "#64748B",
                                                      fontFamily: "Inter",
                                                      fontSize: "14px",
                                                      fontWeight: 700,
                                                      lineHeight: "24px",
                                                      letterSpacing: 0,
                                                  }}>{configJSON.commentsTitle}
                                                  <span style={{
                                                    color: "#DC2626",
                                                    marginLeft: '3px'
                                                  }}>*</span></Typography>
                                                  <Textarea 
                                                    minRows={4}
                                                    error={this.state.inputErrors.descriptionOfIssue}
                                                    value={this.state.description_of_issue}
                                                    name="description_of_issue"
                                                    placeholder={configJSON.descPlaceHolder} 
                                                    onChange={(e) => this.handleInput(e.target.name, e.target.value)}
                                                    slotProps={{
                                                      textarea: {
                                                        maxLength: 3000
                                                      }
                                                    }}
                                                    sx={{
                                                    color: "currentColor",
                                                    fontSize: "16px",
                                                    border: this.state.inputErrors.descriptionOfIssue ? "1px solid rgb(211, 47, 47) !important" : "1px solid #CBD5E1",
                                                    padding: "10px 16px",
                                                    borderRadius: "8px", 
                                                    fontFamily: "Inter",
                                                    background: "#fff",

                                                    ".MuiTextarea-textarea": {
                                                      overflow: "auto !important",
                                                      maxHeight: "117px",
                                                      height: "100% !important"
                                                    }
                                                  }} />
                                                  {this.state.inputErrors.descriptionOfIssue ?
                                                    <Box sx={{
                                                        color: '#DC2626',
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        fontFamily: "Inter"
                                                    }}>{errorMessages.descErrorMsg}</Box> : null}
                                                  <span style={{
                                                    color: "#94A3B8",
                                                    fontSize: "12px",
                                                    fontWeight: 400
                                                  }}>Max 3000 characters</span>
                                            </CssVarsProvider>
                                          </Stack>
                                      </Stack>
                                      <CustomButton 
                                        disabled={this.state.btnDisable} 
                                        onClick={this.onSubmit}
                                        sx={{
                                          background: this.state.btnDisable ? "#F1F5F9" : "linear-gradient(99.09deg, #FCD34D 2.64%, #D97706 100%)",
                                          color: this.state.btnDisable ? "#64748B" : "#FFFFFF"
                                        }}
                                      >{configJSON.btnExampleTitle}</CustomButton>
                                   </Stack>
                               </Grid>
                           </Grid>
                       </Grid>
                   </Grid>
                   <Grid item xs={12} md={6}>
                       <Box sx={styles.imgBox}>
                           <CustomImg src={headPic.default} />
                       </Box>
                   </Grid>
                </Grid>
                </Box>
            </Layout>
            <SuccessModal open={this.state.isModalOpen} />
            {/* Customizable End Start */}
        </ThemeProvider>
      </StyledEngineProvider>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

// Customizable Area Start

const CustomImg = styled('img')(({ theme }) => ({
  width: '100%',
  marginTop: '42px',
  marginBottom: '40px',
  height: '100%',
  objectFit:'cover',
  [theme.breakpoints.up('md')]: {
      borderRadius: '64px 0px 0px 64px',
      height: '73%',
  },
}));

const CustomButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "Inter",
    textTransform: "initial",
    letterSpacing: 0,
    height: "56px",
    borderRadius: "8px",
    color: "#fff",
    width: "100%"
})

const styles = {
  Hero : {
      alignItems: 'center',
      display: 'flex',
      width: '71px',
      height: "26px",
      marginTop: '58px',
      marginLeft: '34px'
  },
  container: {
    alignItems: 'center', 
    margin: {xs: '1rem 0 0 0', lg: '90px 0 0 0'},
    padding: { xs: '0 1rem', md: '0 70px 0 70px', lg: '0 105px 0 105px' },
    display: 'flex',
    width: '100%'
  },
  grid : {
      justifyContent: 'center',
      display: 'flex'
  },
  headImg : {
      width: '100px',
      height: '100px',
      display: 'flex',
      justifyContent: 'center'
  },
  grid2 : {
      width: '100%'
  },
  imgBox : {
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'center',
      height: { xs: '100%', md: '115%', lg: '120%' },
      width: "100%"
  },
  success: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      color: "#059669",
      fontSize: "30px",
      fontWeight: "400",
      fontFamily:"Inter"
  },
  successBox: {
      marginTop:'20px'
  },
  successContainer : {
      display: "flex",
      alignItems: 'center',
      margin: { xs: '100px 0 100px 0',md: '200px 0 0 0', lg: '300px 0 0 0' },
      justifyContent: 'center',
      padding: { xs: '0 50px 0 50px', md: '0 70px 0 70px', lg: '0 105px 0 105px' },
      width: '100%',
  },
  successheadImg : {
      width: '120px',
      height: '120px',
      display: 'flex',
      justifyContent: 'center'
  },
  successHero : {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      width: '71px',
      height: "26px",
      marginTop: '58px',
      marginLeft: '34px',
      
  },
  FontFam:{
      fontFamily:"Inter"
  }
};

// Customizable Area End
