Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.dashboard = "Dashboard";
exports.settings = "Settings";
exports.healthWallet = "Health wallet";
exports.myCase = "My cases";
exports.getRecentActivityApi = "/bx_block_audittrail/activity_logs"
exports.getUserProfileEndPoint = "/bx_block_profile/profile";
// Customizable Area End