import React from 'react';
import {
  Typography,
  Box,
  Stack
} from "@mui/material";
const {Theme} = require("../theme");
const { InfoBox, CustomDatePicker} = Theme;
const { userProfileObj } = require("../config");
import Grid from '@mui/material/Grid';
import { InputErrors, Relationship, User } from '../CustomisableUserProfilesController';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from './InputBase.web';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface DependantProps {
  inputErrors: Omit<InputErrors, "emailError" | "phoneError"> & { relationShipError: boolean };
  handleInput: any;
  userInput: Omit<User, "participantId" | "email" | "phoneNumber"> & { relationship: Relationship | null };
  errorMessages: Record<string, string>;
}

export function getPersonalInfoInput({userInput, inputErrors, handleInput, errorMessages}: any) {
  return (
    <Grid item xs={12} lg={6} style={{ paddingRight: "2rem" }}>
      <InfoBox gap={"16px"}>
        <Typography component={"h1"} className="infoBox-headline">{userProfileObj.personalInfoTxt}</Typography>
        <Stack gap={"18px"}>
          <InputBase
            label={userProfileObj.firstNameTxt}
            value={userInput.firstName}
            error={inputErrors.firstNameError}
            name='firstName'
            inputProps={{ maxLength: 64 }}
            placeholder="Enter first name"
            handleInput={handleInput} testId={'firstName'} errorTitle={errorMessages.firstNameError} />
          <InputBase
            label={userProfileObj.lastNameTxt}
            value={userInput.lastName}
            error={inputErrors.lastNameError}
            name='lastName'
            inputProps={{ maxLength: 64 }}
            placeholder="Enter last name"
            handleInput={handleInput} testId={'lastName'} errorTitle={errorMessages.lastNameError} />
          <Stack gap={"4px"}>
            <Typography className="infoBox-title" style={styles.label}>{userProfileObj.birthDateTxt}</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker
                value={dayjs(userInput.birthDate)}
                defaultValue={dayjs(userInput.birthDate)}
                format='MM-DD-YYYY'
                sx={{ width: "100%", margin: 0 }}
                onChange={(value: any) => handleInput("birthDate", value)}
                slotProps={{textField: {
                  error: inputErrors.birthDateError,
                  style: {
                  }
                }}}
              />
            </LocalizationProvider>
            {inputErrors.birthDateError ?
              <Box sx={styles.errorMsg}>{errorMessages.birthDateError}</Box> : null}
          </Stack>
        </Stack>
      </InfoBox>
    </Grid>
  )
}

const AddNewDependant = ({ inputErrors, handleInput, userInput, errorMessages }: DependantProps) => {
  return (
    <Grid container className='dependant-block' style={styles.editProfile} rowGap={"64px"}>
      {getPersonalInfoInput({ inputErrors, handleInput, userInput, errorMessages })}
      <Grid item xs={12} lg={6} className="pl-2">
        <InfoBox gap={"16px"}>
          <Typography component={"h1"} className="infoBox-headline">{userProfileObj.phpMembershipTxt}</Typography>
          <Stack gap={"24px"}>
            <Stack gap={"4px"}>
              <Typography className="infoBox-title" style={styles.label}>{userProfileObj.relationshipTxt}</Typography>
              <Box sx={{ minWidth: 120, margin: 0 }}>
                <FormControl fullWidth>
                  <Select
                    name='relationship'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{
                      borderRadius: '8px',
                      height: "56px",
                      textTransform: "capitalize"
                    }}
                    error={inputErrors.relationShipError}
                    value={userInput.relationship || "select option"}
                    onChange={(e) => handleInput(e.target.name, e.target.value)}
                  >
                    <MenuItem value={Relationship.SELECT_OPTION} style={{textTransform: "capitalize"}}>{Relationship.SELECT_OPTION}</MenuItem>
                    <MenuItem value={Relationship.SPOUSE} style={{textTransform: "capitalize"}}>{Relationship.SPOUSE}</MenuItem>
                    <MenuItem value={Relationship.CHILD} style={{textTransform: "capitalize"}}>{Relationship.CHILD}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {inputErrors.relationShipError ?
                <Box sx={{
                  color: '#DC2626',
                  fontSize: '12px',
                  fontWeight: '400',
                  fontFamily: "Inter"
                }}>{errorMessages.relationShipError}</Box> : null}
            </Stack>
          </Stack>
        </InfoBox>
      </Grid>
    </Grid>
  )
}
const styles = {
  editProfile: {
    marginTop: "2rem",
    padding: "0 0 0 2rem",
    maxWidth: "calc(100% - 100px)",
  },
  label: {
    fontSize: '14px'
  },
  phoneAdornment: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#0F172A'
  },
  errorMsg: {
    color: '#DC2626',
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: "Inter"
  },
}

export default AddNewDependant