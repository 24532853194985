import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export enum CONTACT_METHOD {
  SELECT_OPTION="Select option",
  PHONE="Phone",
  EMAIL="Email"
}
export const errorMessages = {
  selectErrorMsg: "Please select an option",
  descErrorMsg: "Please add details of the issue"
}
export enum Relationship {
  SELECT_OPTION="Select option",
  SPOUSE="spouse",
  CHILD="child"
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;

  submitting_for: string;
  preferred_method_of_contact: string;
  description_of_issue: string;
  inputErrors: {
    submittingForError: boolean,
    preferredMethodOfContactError: boolean,
    descriptionOfIssue: boolean,
  },
  btnDisable: boolean,
  isModalOpen: boolean,
  userId: string,
  dependantsArray: {id: string, fullName: string, relationship: Relationship}[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;

  saveQueryApiCallId: string;
  getDependentApiCallId: string;
  getUserProfileCallId: string;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.saveQueryApiCallId = "";
    this.getDependentApiCallId = "";
    this.getUserProfileCallId = "";

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,

      submitting_for: CONTACT_METHOD.SELECT_OPTION,
      preferred_method_of_contact: CONTACT_METHOD.SELECT_OPTION,
      description_of_issue: "",

      inputErrors: {
        submittingForError: false,
        preferredMethodOfContactError: false,
        descriptionOfIssue: false,
      }, 
      btnDisable: false,
      isModalOpen: false,
      dependantsArray: [],
      userId: "",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    window.scrollTo(0, 0);
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getCustomizableProfile()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getContactUsList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.contactUsApiCallId) {
          this.setState({ contactUsList: responseJson.data });
        }
        if (apiRequestCallId === this.addContactApiCallId) {
          this.props.navigation.goBack();
        }
      } else if (
        responseJson &&
        responseJson.message &&
        apiRequestCallId === this.deleteContactApiCallId
      ) {
        this.setState({ isVisible: false });
        this.getContactUsList(this.state.token);
      } else if (responseJson && responseJson.errors) {
        if (responseJson.errors) {
          if (apiRequestCallId === this.addContactApiCallId) {
            responseJson.errors.forEach((error: any) => {
              if (error.contact) {
                this.showAlert(configJSON.errorTitle, error.contact.join("."));
              }
            });
          }
        }
      }
    }
    this.handleGetUserProfileResponse(apiRequestCallId, message)
    this.handleContactApiResponse(apiRequestCallId, message)
    this.handleGetDependentResponse(apiRequestCallId, message)
    // Customizable Area End
  }

  // Customizable Area Start
  handleInput = (name: string, value: any) => {
    const { submitting_for, preferred_method_of_contact, description_of_issue, inputErrors } = this.state;
    const updatedErrors = {
      ...inputErrors,
      submittingForError: name === "submitting_for" ? value === CONTACT_METHOD.SELECT_OPTION : inputErrors.submittingForError,
      preferredMethodOfContactError: name === "preferred_method_of_contact" ? value === CONTACT_METHOD.SELECT_OPTION : inputErrors.preferredMethodOfContactError,
      descriptionOfIssue: name === "description_of_issue" ? !value : inputErrors.descriptionOfIssue
    };

    if(name === "submitting_for"){
      this.setState({submitting_for: value})
    }
    else if(name === "preferred_method_of_contact"){
      this.setState({preferred_method_of_contact: value})
    }
    else if(name === "description_of_issue"){
      this.setState({description_of_issue: value})
    }
    if(this.state.submitting_for !== CONTACT_METHOD.SELECT_OPTION && this.state.preferred_method_of_contact !== CONTACT_METHOD.SELECT_OPTION && this.state.description_of_issue) {
      this.setState({btnDisable: false})
    }
    this.setState({
      inputErrors: updatedErrors,
      btnDisable: value === CONTACT_METHOD.SELECT_OPTION || 
                  (name === "submitting_for" ? value === CONTACT_METHOD.SELECT_OPTION : submitting_for === CONTACT_METHOD.SELECT_OPTION) ||
                  (name === "preferred_method_of_contact" ? value === CONTACT_METHOD.SELECT_OPTION : preferred_method_of_contact === CONTACT_METHOD.SELECT_OPTION) ||
                  (name === "description_of_issue" ? !value : !description_of_issue)
    });
  }
  
  validateForm = () => {
    let errorObj = {
      preferredMethodOfContactError: false,
      descriptionOfIssue: false,
      submittingForError: false
    }
    let isDisabled = false;
    if (!this.state.submitting_for || this.state.submitting_for === CONTACT_METHOD.SELECT_OPTION) {
      errorObj.submittingForError = true;
      isDisabled = true;
    }
    if (!this.state.preferred_method_of_contact || this.state.preferred_method_of_contact === CONTACT_METHOD.SELECT_OPTION) {
      errorObj.preferredMethodOfContactError = true;
      isDisabled = true;
    }
    if (!this.state.description_of_issue) {
      errorObj.descriptionOfIssue = true;
      isDisabled = true;
    }
    this.setState({inputErrors: {...errorObj}, btnDisable: isDisabled})
    if(!this.state.submitting_for || !this.state.preferred_method_of_contact || !this.state.description_of_issue) {
      return false
    }
    return true
  }
  redirectToMainPage = () => {
      this.setState({isModalOpen: true}, () => {
        setTimeout(() => {
          this.setState({isModalOpen: false})
          const message: Message = new Message(getName(MessageEnum.NavigationMessage));
          message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(message);
        }, 3000)
      })
  }
  onSubmit = () => {
    if(this.validateForm()) {
      this.postQueryApi()
    }
  }
  getCustomizableProfile = () => {
    const authToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: authToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getUserDependents = () => {
    const authToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDependentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listDependentEndPoint}?dependent[profile_id]=${this.state.userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  postQueryApi = () => {
    const authToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: authToken
    };
    const httpBody = {
      contact: {
        description_of_issue: this.state.description_of_issue,
        preferred_method_of_contact: this.state.preferred_method_of_contact,
        submitting_for: this.state.submitting_for
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveQueryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postContactUsAPiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleContactApiResponse = (id: string, message:Message) => {
    if(id === this.saveQueryApiCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error) return
      if(responseJson.errors && responseJson.errors[0]?.token) {
        this.redirectToSignInPage()
      }
      if(responseJson.contact) {
        this.redirectToMainPage()
      }
    }
  }
  handleGetUserProfileResponse = (id: string, message:Message) => {
    if(id === this.getUserProfileCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error) return
      if(responseJson.errors && responseJson.errors[0]?.token) {
        this.redirectToSignInPage()
      }
      if(responseJson) {
        console.log({responseJson})
        this.setState({userId: responseJson.id, dependantsArray: [{
          id: responseJson.id,
          fullName: `${responseJson.first_name} ${responseJson.last_name}`,
          relationship: responseJson.relationship
        }]}, () => this.getUserDependents())
      }
    }
  }
  handleGetDependentResponse = (id: string, message:Message) => {
    if(id === this.getDependentApiCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson?.error) return
      if(responseJson?.errors && responseJson.errors[0]?.token) {
        this.redirectToSignInPage()
      }
      if(responseJson) {
        const arr = responseJson.map((item: any) => ({
          id: item.id,
          fullName: `${item.first_name} ${item.last_name}`,
          relationship: item.relationship
        }))
        this.setState({dependantsArray: [...this.state.dependantsArray, ...arr]})
      }
    }
  }
  redirectToSignInPage = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };
  // Customizable Area End
}
