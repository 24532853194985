import React, { Component } from "react";
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import { Stack, Typography, Button, InputLabel, TextField } from '@mui/material/';
import DialogContent from '@mui/material/DialogContent';
import { phcPic } from "../assets"
import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import * as Yup from 'yup';
import { sx } from "./SuccessDialog.web";

interface TouchedType {
    firstName: boolean,
    lastName: boolean,
    cardNumber: boolean,
    expirationDate: boolean,
    cvc: boolean,
    addressLine1: boolean,
    addressLine2: boolean,
    city: boolean,
    state: boolean,
    zipCode: boolean,
}

interface ErrorType {
    firstName: string,
    lastName: string,
    cardNumber: string,
    expirationDate: string,
    cvc: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    zipCode: string,
}
type Props = {
    open: boolean; 
    handleClose: any;
};
type State = {
    open: boolean;
    open2: boolean;
};

const initialValues = {
    firstName: '',
    lastName: '',
    cardNumber: '',
    expirationDate: '',
    cvc: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
};
const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    cardNumber: Yup.string().required('Card Number is required'),
    expirationDate: Yup.string()
        .required('Expiration Date is required'),
    cvc: Yup.string().required('CVC is required'),
    addressLine1: Yup.string().required('Address Line 1 is required'),
    addressLine2: Yup.string().required('Address Line 2 is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zipCode: Yup.string().required('ZIP Code is required'),
});
export default class StripeUpdatePaymentMethod extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            open: this.props.open,
            open2: false,
        };
    }
    componentDidMount(): void {
        console.log("currentModal",this.props);
        
    }
    formatExpirationDate = (value: string) => {
        const cleaned = value.replace(/\D+/g, '');
        let formatted = cleaned;

        if (cleaned.length > 2) {
            formatted = `${cleaned.slice(0, 2)}/${cleaned.slice(2, 4)}`;
        }
        return formatted;
    };
    handleExpirationDateChange = (e: any, setFieldValue: Function) => {
        const formattedExpirationDate = this.formatExpirationDate(e.target.value);
        setFieldValue('expirationDate', formattedExpirationDate);
    };
    formatCardNumber = (value: string) => {
        const cleaned = value.replace(/\D+/g, '');

        const formatted = cleaned.match(/.{1,4}/g)?.join('-') || cleaned;

        return formatted;
    };
    handleCardNumberChange = (e: any, setFieldValue: any) => {
        const formattedCardNumber = this.formatCardNumber(e.target.value);
        setFieldValue('cardNumber', formattedCardNumber);
    };
    getErrorMessage = (
        touched: FormikTouched<TouchedType>,
        errors: FormikErrors<ErrorType>,
        value: string
    ) => {
        return (
            touched[value as keyof TouchedType] &&
            errors[value as keyof ErrorType] && (
                <Typography
                    style={{ marginTop: "2px", fontSize: "12px", color: "#DC2626", fontWeight: 400 }}
                >
                    {errors[value as keyof TouchedType]}
                </Typography>
            )
        );
    };

    render() {
        return (
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={this.state.open}
                sx={{
                    ...sx,
                    ".MuiDialog-paperWidthSm": {
                        borderRadius: "16px",
                        padding: "12px 0",
                        maxWidth: "880px",
                        width: "100%",
                        fontFamily: "Inter"
                    },
                    ".MuiDialogContent-root": {
                        overflow: "visible",
                        padding: "24px"
                    }
                }}
            >
                <DialogContent>
                    <Stack height={"100%"} alignItems={"center"} justifyContent={"center"} gap={"56px"}>
                        <Stack justifyContent={"center"} alignItems={"center"} gap={"8px"}>
                            <img src={phcPic.default} style={{ width: "75px", height: "75px" }} />
                            <Stack justifyContent={"center"} alignItems={"center"} gap={"4px"} mb={"1.5rem"}>
                                <Typography sx={{
                                    color: "#0F172A",
                                    fontSize: "24px",
                                    fontFamily: "Inter"
                                }}>Updating payment method</Typography>
                                <Typography sx={{
                                    color: "#64748B",
                                    fontSize: "14px",
                                    fontFamily: "Inter"
                                }}>Please fill in all mandatory fields below to update the payment method</Typography>
                            </Stack>
                            <Formik
                                data-test-id='formik'
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={(values) => {
                                    console.log({ values })
                                    this.props.handleClose("card-verification", true)
                                }}>
                                {({ handleChange, values, handleBlur, touched, errors, setFieldValue }) => (
                                    <Form data-test-id="subscription-form" style={{ margin: 0 }}>
                                        <Stack gap={"38px"}>
                                            <Stack gap={"12px"}>
                                                <Typography sx={{
                                                    color: "#64748B",
                                                    fontWeight: 700,
                                                    fontFamily: "Inter",
                                                    fontSize: "16px"
                                                }}>Card Information</Typography>
                                                <Grid container spacing={"20px"}>
                                                    <Grid item style={{ paddingTop: '15px' }} xs={12} md={6} lg={4}>
                                                        <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="cardNumber">
                                                            Card Number
                                                        </InputLabel>
                                                        <TextField
                                                            data-test-id="cardNumber"
                                                            name="cardNumber"
                                                            sx={{
                                                                "input": {
                                                                    padding: "10px 8px",
                                                                    height: "56px",
                                                                    boxSizing: "border-box",
                                                                },
                                                                ".MuiOutlinedInput-notchedOutline": {
                                                                    border: "1px solid #CBD5E1",
                                                                    borderRadius: "8px",
                                                                }
                                                            }}
                                                            placeholder="6812-1072-2028-1178"
                                                            error={touched.cardNumber && Boolean(errors.cardNumber)}
                                                            value={values.cardNumber}
                                                            onChange={(e) => this.handleCardNumberChange(e, setFieldValue)}
                                                            onBlur={handleBlur}
                                                            fullWidth
                                                            variant="outlined"
                                                            inputProps={{
                                                                maxLength: 19,
                                                            }}
                                                        />
                                                        {this.getErrorMessage(touched, errors, "cardNumber")}
                                                    </Grid>
                                                    <Grid style={{ paddingTop: '15px' }} item xs={12} md={6} lg={4}>
                                                        <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="expirationDate">
                                                            Expiration Date
                                                        </InputLabel>
                                                        <TextField
                                                            data-test-id="expirationDate"
                                                            sx={{
                                                                "input": {
                                                                    padding: "10px 8px",
                                                                    height: "56px",
                                                                    boxSizing: "border-box",
                                                                },
                                                                ".MuiOutlinedInput-notchedOutline": {
                                                                    border: "1px solid #CBD5E1",
                                                                    borderRadius: "8px",
                                                                }
                                                            }}
                                                            name="expirationDate"
                                                            placeholder="MM/YY"
                                                            error={touched.expirationDate && Boolean(errors.expirationDate)}
                                                            value={values.expirationDate}
                                                            onChange={(e) => this.handleExpirationDateChange(e, setFieldValue)}
                                                            onBlur={handleBlur}
                                                            fullWidth
                                                            variant="outlined"
                                                            inputProps={{
                                                                maxLength: 25,
                                                            }}
                                                        />
                                                        {this.getErrorMessage(touched, errors, "expirationDate")}
                                                    </Grid>
                                                    <Grid style={{ paddingTop: '15px' }} item xs={12} md={6} lg={4}>
                                                        <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="cvc">
                                                            CVC / CCV
                                                        </InputLabel>
                                                        <TextField
                                                            data-test-id="cvc"
                                                            name="cvc"
                                                            placeholder="CVC/CCV"
                                                            error={touched.cvc && Boolean(errors.cvc)}
                                                            value={values.cvc}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            fullWidth
                                                            variant="outlined"
                                                            sx={{
                                                                "input": {
                                                                    padding: "10px 8px",
                                                                    height: "56px",
                                                                    boxSizing: "border-box",
                                                                },
                                                                ".MuiOutlinedInput-notchedOutline": {
                                                                    border: "1px solid #CBD5E1",
                                                                    borderRadius: "8px",
                                                                }
                                                            }}
                                                            inputProps={{
                                                                maxLength: 3,
                                                            }}
                                                        />
                                                        {this.getErrorMessage(touched, errors, "cvc")}
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                            <Stack gap={"12px"}>
                                                <Typography sx={{
                                                    color: "#64748B",
                                                    fontWeight: 700,
                                                    fontFamily: "Inter",
                                                    fontSize: "16px"
                                                }}>Personal Data</Typography>
                                                <Grid container spacing={"20px"}>
                                                    <Grid item style={{ paddingTop: '15px' }} xs={12} md={6} lg={4}>
                                                        <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="firstName">
                                                            First Name
                                                        </InputLabel>
                                                        <TextField
                                                            data-test-id="firstName"
                                                            name="firstName"
                                                            sx={{
                                                                "input": {
                                                                    padding: "10px 8px",
                                                                    height: "56px",
                                                                    boxSizing: "border-box",
                                                                },
                                                                ".MuiOutlinedInput-notchedOutline": {
                                                                    border: "1px solid #CBD5E1",
                                                                    borderRadius: "8px",
                                                                }
                                                            }}
                                                            placeholder="Enter first name"
                                                            error={touched.firstName && Boolean(errors.firstName)}
                                                            value={values.firstName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            fullWidth
                                                            variant="outlined"
                                                            inputProps={{
                                                                maxLength: 19,
                                                            }}
                                                        />
                                                        {this.getErrorMessage(touched, errors, "firstName")}
                                                    </Grid>
                                                    <Grid style={{ paddingTop: '15px' }} item xs={12} md={6} lg={4}>
                                                        <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="lastName">
                                                            Last Name
                                                        </InputLabel>
                                                        <TextField
                                                            data-test-id="lastName"
                                                            sx={{
                                                                "input": {
                                                                    padding: "10px 8px",
                                                                    height: "56px",
                                                                    boxSizing: "border-box",
                                                                },
                                                                ".MuiOutlinedInput-notchedOutline": {
                                                                    border: "1px solid #CBD5E1",
                                                                    borderRadius: "8px",
                                                                }
                                                            }}
                                                            name="lastName"
                                                            placeholder="Enter last name"
                                                            error={touched.lastName && Boolean(errors.lastName)}
                                                            value={values.lastName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            fullWidth
                                                            variant="outlined"
                                                            inputProps={{
                                                                maxLength: 25,
                                                            }}
                                                        />
                                                        {this.getErrorMessage(touched, errors, "lastName")}
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                            <Stack gap={"12px"}>
                                                <Typography sx={{
                                                    color: "#64748B",
                                                    fontWeight: 700,
                                                    fontFamily: "Inter",
                                                    fontSize: "16px"
                                                }}>Billing Address</Typography>
                                                <Stack gap={"24px"}>
                                                    <Grid container spacing={"20px"}>
                                                        <Grid item style={{ paddingTop: '15px' }} xs={12} md={6} lg={4}>
                                                            <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="addressLine1">
                                                                Address Line 1
                                                            </InputLabel>
                                                            <TextField
                                                                data-test-id="addressLine1"
                                                                sx={{
                                                                    "input": {
                                                                        padding: "10px 8px",
                                                                        height: "56px",
                                                                        boxSizing: "border-box",
                                                                    },
                                                                    ".MuiOutlinedInput-notchedOutline": {
                                                                        border: "1px solid #CBD5E1",
                                                                        borderRadius: "8px",
                                                                    }
                                                                }}
                                                                name="addressLine1"
                                                                placeholder="Enter Address Line 1"
                                                                error={touched.addressLine1 && Boolean(errors.addressLine1)}
                                                                value={values.addressLine1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                fullWidth
                                                                variant="outlined"
                                                            />
                                                            {this.getErrorMessage(touched, errors, "addressLine1")}
                                                        </Grid>
                                                        <Grid style={{ paddingTop: '15px' }} item xs={12} md={6} lg={4}>
                                                            <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="addressLine2">
                                                                Address Line 2
                                                            </InputLabel>
                                                            <TextField
                                                                data-test-id="addressLine2"
                                                                sx={{
                                                                    "input": {
                                                                        padding: "10px 8px",
                                                                        height: "56px",
                                                                        boxSizing: "border-box",
                                                                    },
                                                                    ".MuiOutlinedInput-notchedOutline": {
                                                                        border: "1px solid #CBD5E1",
                                                                        borderRadius: "8px",
                                                                    }
                                                                }}
                                                                name="addressLine2"
                                                                placeholder="Enter Address Line 2"
                                                                error={touched.addressLine2 && Boolean(errors.addressLine2)}
                                                                value={values.addressLine2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                fullWidth
                                                                variant="outlined"
                                                            />
                                                            {this.getErrorMessage(touched, errors, "addressLine2")}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={"20px"}>
                                                        <Grid item style={{ paddingTop: '15px' }} xs={12} md={6} lg={4}>
                                                            <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="state">
                                                                State
                                                            </InputLabel>
                                                            <TextField
                                                                data-test-id="state"
                                                                sx={{
                                                                    "input": {
                                                                        padding: "10px 8px",
                                                                        height: "56px",
                                                                        boxSizing: "border-box",
                                                                    },
                                                                    ".MuiOutlinedInput-notchedOutline": {
                                                                        border: "1px solid #CBD5E1",
                                                                        borderRadius: "8px",
                                                                    }
                                                                }}
                                                                name="state"
                                                                placeholder="Enter State"
                                                                error={touched.state && Boolean(errors.state)}
                                                                value={values.state}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                fullWidth
                                                                variant="outlined"
                                                            />
                                                            {this.getErrorMessage(touched, errors, "state")}
                                                        </Grid>
                                                        <Grid style={{ paddingTop: '15px' }} item xs={12} md={6} lg={4}>
                                                            <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="city">
                                                                City
                                                            </InputLabel>
                                                            <TextField
                                                                data-test-id="city"
                                                                sx={{
                                                                    "input": {
                                                                        padding: "10px 8px",
                                                                        height: "56px",
                                                                        boxSizing: "border-box",
                                                                    },
                                                                    ".MuiOutlinedInput-notchedOutline": {
                                                                        border: "1px solid #CBD5E1",
                                                                        borderRadius: "8px",
                                                                    }
                                                                }}
                                                                name="city"
                                                                placeholder="Enter City"
                                                                error={touched.city && Boolean(errors.city)}
                                                                value={values.city}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                fullWidth
                                                                variant="outlined"
                                                            />
                                                            {this.getErrorMessage(touched, errors, "city")}
                                                        </Grid>
                                                        <Grid style={{ paddingTop: '15px' }} item xs={12} md={6} lg={4}>
                                                            <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="zipCode">
                                                                ZIP Code
                                                            </InputLabel>
                                                            <TextField
                                                                data-test-id="zipCode"
                                                                sx={{
                                                                    "input": {
                                                                        padding: "10px 8px",
                                                                        height: "56px",
                                                                        boxSizing: "border-box",
                                                                    },
                                                                    ".MuiOutlinedInput-notchedOutline": {
                                                                        border: "1px solid #CBD5E1",
                                                                        borderRadius: "8px",
                                                                    }
                                                                }}
                                                                name="zipCode"
                                                                placeholder="Enter ZIP Code"
                                                                error={touched.zipCode && Boolean(errors.zipCode)}
                                                                value={values.zipCode}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                fullWidth
                                                                variant="outlined"
                                                            />
                                                            {this.getErrorMessage(touched, errors, "zipCode")}
                                                        </Grid>
                                                    </Grid>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        <Stack sx={{
                                            mt: "3.5rem",
                                            "@media(max-width: 991px)": {
                                                flexDirection: "column",
                                                gap: "12px"
                                            }
                                        }} justifyContent={"center"} alignItems={"center"} direction={"row"} gap={"38px"} width={"100%"} className='modal-btn-group'>
                                            <Button type="submit" className='btn edit-btn' data-testid="btn1">{"Update payment method"}</Button>
                                            <Button className='btn cancel-btn' data-testid="btn2" onClick={() => this.props.handleClose("update-payment", false)} style={{ color: "#64748B", background: "#F1F5F9" }}>{"Cancel"}</Button>
                                        </Stack>
                                    </Form>
                                )}
                            </Formik>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>)
    }
}