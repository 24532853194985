import * as React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Typography, Stack, Box } from "@mui/material";

interface Props {
    error: boolean;
    handleInput: any;
    label: string;
    value: string;
    name: string;
    errorMessage: string;
    menuItem: { label: string, value: string }[];
}

const MuiSelect = ({ error, handleInput, menuItem, label, value, name, errorMessage }: Props) => {
    return (
        <Stack gap={"4px"}>
            <Typography sx={{
                color: "#64748B",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "24px",
                letterSpacing: 0,
            }}>{label}<span style={{
                color: "#DC2626",
                marginLeft: '3px'
            }}>*</span></Typography>
            <Select
                name={name}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{
                    borderRadius: '8px',
                    height: "56px",

                    ".MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #CBD5E1",
                        padding: "0 !important",
                        borderRadius: "8px",
                    },
                    ".MuiSelect-iconOutlined": {
                        color: "#1E293B"
                    },
                    ".Mui-selected": {
                        background: "#FFFBEB"
                    },
                }}
                slotProps={{
                }}
                error={error}
                value={value}
                onChange={(e) => handleInput(e.target.name, e.target.value)}
            >
                {menuItem.map((item, index) => <MenuItem key={index} value={item.value} style={{ color: "#0F172A", fontSize: "16px", fontWeight: 400 }}>{item.label}</MenuItem>)}
            </Select>
            {error ?
                <Box sx={{
                    color: '#DC2626',
                    fontSize: '12px',
                    fontWeight: '400',
                    fontFamily: "Inter"
                }}>{errorMessage}</Box> : null}
        </Stack>
    )
}

export default MuiSelect